import { useAppSelector } from "@simplicate/state";
import i18next from "i18next";
import HttpBackend, { HttpBackendOptions } from "i18next-http-backend";
import { PropsWithChildren, useEffect } from "react";
import { initReactI18next, useTranslation } from "react-i18next";

const FALLBACK_LANGUAGE = "nl";
const DEFAULT_NAMESPACE = "general";

// eslint-disable-next-line import/no-named-as-default-member -- using the default provides necessary context
i18next
  .use(initReactI18next)
  .use(HttpBackend)
  .init<HttpBackendOptions>({
    // also update tooling/translations/config/i18next-parser.config.json if you change this
    defaultNS: DEFAULT_NAMESPACE,
    ns: [DEFAULT_NAMESPACE],
    compatibilityJSON: "v3",
    lng: FALLBACK_LANGUAGE,
    fallbackLng: FALLBACK_LANGUAGE,
    supportedLngs: ["en", "nl", "ci"],
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    parseMissingKeyHandler:
      // TODO: Implement TranslationProvider boundaries using react's suspense setting enabled allowing clear asset
      // loading boundaries. This requires a revision of how the translation namespaces are loaded and translations are
      // used in general.
      // istanbul ignore next -- this prevents text flashing when the resources are loaded
      () => "",
    cleanCode: true,
    backend: {
      loadPath: "/simplitatic/locales/{{lng}}/{{ns}}.json",
      crossDomain: false,
    },
  })
  .catch(
    /* istanbul ignore next */ () => {
      // TODO: implement error reporting
      console.error("Failed to initialize i18next -- translations will not work!");
    },
  );

export const TranslationsProvider = ({ children }: PropsWithChildren) => {
  const { locale } = useAppSelector(/* istanbul ignore next */ ({ state }) => state.locale);
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale).catch(
      /* istanbul ignore next */ () => {
        // TODO: implement error reporting
        console.error(`Failed to change language to ${locale}`);
      },
    );
  }, [locale, i18n]);

  return children;
};
