import { useTranslation } from "@simplicate/translations";
import { Portal, usePortalContext, MODAL_ANCHOR, showToast } from "@simplicate/ui";
import { useState, useEffect } from "react";
import { GroupType, useCreateServiceGroupMutation } from "../../../../../data";
import { LoadingFallback } from "../LoadingFallback";
import { ServiceGroupModal } from "../ServiceGroupModal";

type AddServiceGroupModalProps = {
  saleId: string;
  isOpen: boolean;
  onClose: () => void;
};

export const AddServiceGroupModal = ({ saleId, onClose, isOpen }: AddServiceGroupModalProps) => {
  const [createServiceGroup, { isError, isSuccess, isLoading, reset }] = useCreateServiceGroupMutation();
  const { targetRefs } = usePortalContext();

  const { t } = useTranslation("grouped_services_manager");

  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [groupType, setGroupType] = useState<GroupType>("all_included");
  const [hasError, setHasError] = useState<boolean>(false);

  const resetForm = () => {
    onClose();
    setName("");
    setDescription("");
    setGroupType("all_included");
    setHasError(false);
  };

  const handleNameChange = (newName: string) => {
    setName(newName);
    if (!newName) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  };

  const handleCreateServiceGroup = async () => {
    if (!name || hasError) {
      setHasError(true);

      return;
    }

    await createServiceGroup({
      saleId: saleId,
      name: name,
      description: description,
      type: groupType,
    });

    resetForm();
  };

  useEffect(() => {
    if (isSuccess) {
      onClose();
      setName("");
      setDescription("");
      reset();

      return;
    }
    if (isError) {
      showToast({ message: t("error_messages.failed_to_add_group"), type: "error" });
    }
  }, [isError, isSuccess, onClose, reset, t]);

  return (
    <>
      <ServiceGroupModal
        isOpen={isOpen}
        onClose={resetForm}
        onSubmit={(e) => {
          e.preventDefault();
          void handleCreateServiceGroup();
        }}
        name={name}
        description={description}
        hasError={hasError}
        onNameChange={(e) => handleNameChange(e.target.value)}
        onDescriptionChange={(e) => setDescription(e.target.value)}
        onGroupTypeChange={setGroupType}
        groupType={groupType}
      />
      {isLoading && (
        <Portal targetRef={targetRefs[MODAL_ANCHOR]}>
          <LoadingFallback text={t("loading_messages.add_group")} />
        </Portal>
      )}
    </>
  );
};
