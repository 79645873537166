import { InvoiceMethod } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { UniqueComponentId } from "primereact/utils";
import { useMemo } from "react";
import { CanRegisterToggleHeader } from "./CanRegisterToggleHeader/CanRegisterToggleHeader";
import { FormikErrors } from "./FormikErrors";
import { CostTypeGrid } from "./grids/CostTypeGrid";
import { UseProjectServiceFormReturnType } from "./hooks/useProjectServiceForm";

type CostTypeSectionProps = {
  costTypeHandlers: UseProjectServiceFormReturnType["costTypeHandlers"];
  values: UseProjectServiceFormReturnType["values"];
  touched: UseProjectServiceFormReturnType["touched"];
  isDefaultServiceUndefined: boolean;
  errors: UseProjectServiceFormReturnType["errors"];
};

export const CostTypeSection = ({
  costTypeHandlers: handlers,
  values,
  touched,
  isDefaultServiceUndefined,
  errors,
}: CostTypeSectionProps) => {
  const invoiceMethodIsTimeAndExpenses = values.invoiceMethod === InvoiceMethod.time_and_expenses;
  const isCostTypeGridDisabled = !values.canRegisterCosts || isDefaultServiceUndefined;
  const isCanRegisterCostsLocked = useMemo(
    () =>
      (values.costTypes?.some(({ hasRegistrations }) => hasRegistrations) ?? false) &&
      (values.canRegisterCosts ?? false),
    [values.canRegisterCosts, values.costTypes],
  );

  const { t } = useTranslation("project_services");

  return (
    <>
      <CanRegisterToggleHeader
        isDefaultServiceUndefined={isDefaultServiceUndefined}
        canRegister={values.canRegisterCosts ?? false}
        setCanRegister={handlers.setCanRegisterCosts}
        isCanRegisterLocked={isCanRegisterCostsLocked}
        labelText={t("expense_registration")}
        testId="expense_registration_toggle"
        tooltipText={t("cannot_disable_can_register_costs")}
        errors={
          <FormikErrors touched={touched.canRegisterCosts} key={UniqueComponentId()} error={errors?.canRegisterCosts} />
        }
      />
      <CostTypeGrid
        value={values.costTypes}
        onAddCostType={handlers.addCostType}
        onRemoveCostType={handlers.removeCostType}
        onLabelChange={handlers.setLabelForCostType}
        onQuantityChange={handlers.setQuantityForCostType}
        onMarginChange={handlers.setMarginForCostType}
        onPurchasePriceChange={handlers.setPurchasePriceForCostType}
        onSellingPriceChange={handlers.setSellingPriceForCostType}
        disabled={isCostTypeGridDisabled}
        showIsInvoiceableColumn={invoiceMethodIsTimeAndExpenses}
        onIsInvoiceableToggled={handlers.toggleIsInvoiceableForCostType}
      />
    </>
  );
};
