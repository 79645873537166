import { NumberFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { Tooltip } from "@simplicate/ui";
import { memo } from "react";
import styles from "./InvoiceQuantityInput.module.scss";

type InvoiceQuantityInputProps = {
  value?: number;
  onChange: (value: number | undefined) => void;
  disabled?: boolean;
  tooltip?: string;
};

export const InvoiceQuantityInput = memo(function InvoiceQuantityInput({
  value,
  onChange,
  disabled = false,
  tooltip,
}: InvoiceQuantityInputProps) {
  const { t } = useTranslation("project_services");

  const input = (
    <div className={styles.invoiceQuantity}>
      <NumberFormat
        testId="invoice-quantity"
        displayType="input"
        size="normal"
        label={t("invoice_quantity_label")}
        decimalScale={2}
        textAlign="left"
        value={value}
        disabled={disabled}
        defaultValue={1}
        onValueChange={
          /* istanbul ignore next -- Component is mocked as <span/> making input test cases impossible */ ({
            floatValue,
          }) => onChange(floatValue)
        }
      />
    </div>
  );

  return tooltip ? (
    <Tooltip message={tooltip} position="right">
      {input}
    </Tooltip>
  ) : (
    input
  );
});
