import { Big } from "../../../data";
import type { CostTypeInForm } from "../types";

type UseServiceTotalProps = {
  hourTypesSpecifiedTotalAmount: Big;
  costTypes: CostTypeInForm[];
};

export const useServiceTotal = ({ hourTypesSpecifiedTotalAmount, costTypes }: UseServiceTotalProps) => {
  const costTypesTotal = costTypes.reduce(
    (costTypesTotal, current) => costTypesTotal.add(current.total.amount),
    Big(0),
  );

  return hourTypesSpecifiedTotalAmount.add(costTypesTotal);
};
