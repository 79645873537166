import { Big } from "../../data";
import { type CostTypeInForm, type MoneyInForm } from "./types";

export function setQuantity(costType: CostTypeInForm, quantity: number | undefined): CostTypeInForm {
  if (!costType.usePricePerUnit) {
    return costType;
  }

  const costTypeWithNewQuantity = {
    ...costType,
    quantity,
  };

  return recalculateTotal(costTypeWithNewQuantity, costTypeWithNewQuantity.sellingPrice, quantity ?? 0);
}

export function setPurchasePrice(costType: CostTypeInForm, purchasePriceAmount: Big | undefined): CostTypeInForm {
  const costTypeWithNewPurchasePrice = {
    ...costType,
    purchasePrice: {
      ...costType.purchasePrice,
      amount: purchasePriceAmount,
    },
  };

  const costTypeWithNewSellingPrice = recalculateSellingPrice(
    costTypeWithNewPurchasePrice,
    purchasePriceAmount,
    costTypeWithNewPurchasePrice.margin ?? 0,
  );

  return recalculateTotal(
    costTypeWithNewSellingPrice,
    costTypeWithNewSellingPrice.sellingPrice,
    costTypeWithNewSellingPrice.quantity ?? 0,
  );
}

export function setMargin(costType: CostTypeInForm, margin: number | undefined): CostTypeInForm {
  const costTypeWithNewMargin = {
    ...costType,
    margin,
  };

  const costTypeWithNewSellingPrice = recalculateSellingPrice(
    costTypeWithNewMargin,
    costType.purchasePrice?.amount,
    margin ?? 0,
  );

  return recalculateTotal(
    costTypeWithNewSellingPrice,
    costTypeWithNewSellingPrice.sellingPrice,
    costTypeWithNewSellingPrice.quantity ?? 0,
  );
}

export function setSellingPrice(costType: CostTypeInForm, sellingPriceAmount: Big | undefined): CostTypeInForm {
  const costTypeWithNewSellingPrice = {
    ...costType,
    sellingPrice: {
      ...costType.sellingPrice,
      amount: sellingPriceAmount,
    },
  };

  const costTypeWithNewMargin = {
    ...costTypeWithNewSellingPrice,
    margin: recalculateMargin(costTypeWithNewSellingPrice, sellingPriceAmount).margin,
  };

  return recalculateTotal(
    costTypeWithNewMargin,
    costTypeWithNewMargin.sellingPrice,
    costTypeWithNewMargin.quantity ?? 0,
  );
}

function recalculateMargin(costType: CostTypeInForm, sellingPriceAmount: Big | undefined): CostTypeInForm {
  if (
    sellingPriceAmount === undefined ||
    costType.purchasePrice.amount === undefined ||
    costType.purchasePrice.amount.eq(Big(0))
  ) {
    /* We do not alter margin if the sellingPrice or purchasePrice is not set
        (because this means the user is still entering the input)
       or if the purchasePrice is 0
        (because this would result in a division by zero)
    */
    return costType;
  }

  return {
    ...costType,
    margin: sellingPriceAmount.div(costType.purchasePrice.amount).sub(1).mul(100).toNumber(),
  };
}

function recalculateSellingPrice(
  costType: CostTypeInForm,
  purchasePriceAmount: Big | undefined,
  margin: number,
): CostTypeInForm {
  return {
    ...costType,
    sellingPrice: {
      ...costType.sellingPrice,
      amount: purchasePriceAmount?.mul(1 + margin / 100) ?? Big(0),
    },
  };
}

function recalculateTotal(costType: CostTypeInForm, sellingPrice: MoneyInForm, quantity: number): CostTypeInForm {
  if (costType.usePricePerUnit) {
    return {
      ...costType,
      total: {
        ...costType.total,
        amount: sellingPrice.amount?.mul(quantity) ?? Big(0),
      },
    };
  }

  return {
    ...costType,
    total: {
      ...costType.total,
      amount: sellingPrice.amount ?? Big(0),
    },
  };
}
