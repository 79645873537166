// istanbul ignore file -- This component will be covered by e2e tests
import { Query } from "@cubejs-client/core";
import { usePageTitle } from "@simplicate/state";
import { TFunction, useTranslation } from "@simplicate/translations";
import { Page } from "@simplicate/ui";
import { size9 } from "@simplicate-software/design-tokens";
import { useMemo } from "react";
import { CubeProvider, Dashboard } from "../../components";
import { ViewMenu } from "../../components/ViewMenu";
import { Column, cubeDimensionToKey } from "../../types";
import {
  DimensionFilterConfig,
  FilterWidget,
  MultiKPIWidget,
  TableWidget,
  TargetKPIWidget,
  type MultiKPIRowProps,
  ViewActionsWidget,
} from "../../widgets";
import { DashboardReportPageTemplate } from "../DashboardReportPageTemplate";
import { useNavigateToDetailsPage } from "./useNavigateToDetailsPage";

const DASHBOARD_ID = "hours-report";
const QUERY: Query = {
  timeDimensions: [
    {
      dimension: "hours_report.timetable_date",
      dateRange: "this month",
    },
  ],
  dimensions: [
    "hours_report.employee_identifier",
    "hours_report.employee_name",
    "hours_report.employee_avatar",
    "hours_report.employee_initials",
  ],
  measures: [
    "hours_report.timetable_available_hours",
    "hours_report.timetable_corrected_value",
    "hours_report.timetable_declarability",
    "hours_report.timetable_declarable_value",
    "hours_report.timetable_productivity",
    "hours_report.timetable_raster_hours",
    "hours_report.timetable_registered_hours",
    "hours_report.timetable_registered_value",
  ],
  order: {
    "hours_report.employee_name": "asc",
  },
};

const AVAILABLE_HOURS_COLUMNS = ["hours_report", "timetable_available_hours"] as const;
const CORRECTED_VALUE_COLUMN = ["hours_report", "timetable_corrected_value"] as const;
const DECLARABILITY_COLUMN = ["hours_report", "timetable_declarability"] as const;
const DECLARABLE_VALUE_COLUMN = ["hours_report", "timetable_declarable_value"] as const;
const EMPLOYEE_AVATAR_DIMENSION = ["hours_report", "employee_avatar"] as const;
const EMPLOYEE_IDENTIFIER_COLUMN = ["hours_report", "employee_identifier"] as const;
const EMPLOYEE_INITIALS_DIMENSION = ["hours_report", "employee_initials"] as const;
const EMPLOYEE_NAME_COLUMN = ["hours_report", "employee_name"] as const;
const PRODUCTIVITY_COLUMN = ["hours_report", "timetable_productivity"] as const;
const RASTER_HOURS_COLUMN = ["hours_report", "timetable_raster_hours"] as const;
const REGISTERED_HOURS_COLUMN = ["hours_report", "timetable_registered_hours"] as const;
const REGISTERED_VALUE_COLUMN = ["hours_report", "timetable_registered_value"] as const;
const SUPERVISOR_IDENTIFIER_COLUMN = ["hours_report", "supervisor_identifier"] as const;
const SUPERVISOR_NAME_COLUMN = ["hours_report", "supervisor_name"] as const;
const TEAM_IDENTIFIER_COLUMN = ["hours_report", "team_identifier"] as const;
const TEAM_NAME_COLUMN = ["hours_report", "team_name"] as const;

const FILTER_CONFIG: DimensionFilterConfig[] = [
  {
    labelDimension: EMPLOYEE_NAME_COLUMN,
    valueDimension: EMPLOYEE_IDENTIFIER_COLUMN,
    placeholder: (t: TFunction<"insights">) => t("hours.employee_placeholder"),
    filterFormat: (value: unknown, t: TFunction<"insights">) => t("filters.employee_filter", { value }),
  },
  {
    labelDimension: TEAM_NAME_COLUMN,
    valueDimension: TEAM_IDENTIFIER_COLUMN,
    placeholder: (t: TFunction<"insights">) => t("hours.team_placeholder"),
    filterFormat: (value: unknown, t: TFunction<"insights">) => t("filters.team_filter", { value }),
  },
  {
    labelDimension: SUPERVISOR_NAME_COLUMN,
    valueDimension: SUPERVISOR_IDENTIFIER_COLUMN,
    placeholder: (t: TFunction<"insights">) => t("hours.supervisor_placeholder"),
    filterFormat: (value: unknown, t: TFunction<"insights">) => t("filters.supervisor_filter", { value }),
  },
];

const LEFT_KPI_CONFIG: MultiKPIRowProps[] = [
  {
    dimension: RASTER_HOURS_COLUMN,
    label: (t: TFunction<"insights">) => t("hours.raster_hours_kpi_label"),
    aggregation: "sum",
    format: "number",
  },
  {
    dimension: REGISTERED_HOURS_COLUMN,
    label: (t: TFunction<"insights">) => t("hours.registered_hours_kpi_label"),
    aggregation: "sum",
    format: "number",
  },
  {
    dimension: AVAILABLE_HOURS_COLUMNS,
    label: (t: TFunction<"insights">) => t("hours.available_hours_kpi_label"),
    aggregation: "sum",
    format: "number",
  },
];

const RIGHT_KPI_CONFIG: MultiKPIRowProps[] = [
  {
    dimension: REGISTERED_VALUE_COLUMN,
    label: (t: TFunction<"insights">) => t("hours.registered_value_kpi_label"),
    aggregation: "sum",
    format: "currency",
  },
  {
    dimension: CORRECTED_VALUE_COLUMN,
    label: (t: TFunction<"insights">) => t("hours.corrected_value_kpi_label"),
    aggregation: "sum",
    format: "currency",
  },
  {
    dimension: DECLARABLE_VALUE_COLUMN,
    label: (t: TFunction<"insights">) => t("hours.declarable_value_kpi_label"),
    aggregation: "sum",
    format: "currency",
  },
];

const COLUMNS_CONFIG: Column[] = [
  {
    dimension: EMPLOYEE_NAME_COLUMN,
    title: (t: TFunction<"insights">) => t("hours.employee_column_title"),
    sortable: true,
    width: "dynamic",
    align: "left",
    avatarConfig: {
      avatarDimension: EMPLOYEE_AVATAR_DIMENSION,
      initialsDimension: EMPLOYEE_INITIALS_DIMENSION,
    },
  },
  {
    dimension: EMPLOYEE_IDENTIFIER_COLUMN,
    title: () => "id",
    hidden: true,
  },
  {
    dimension: RASTER_HOURS_COLUMN,
    title: (t: TFunction<"insights">) => t("hours.timetable_hours_column_title"),
    sortable: true,
    isNumeric: true,
    width: size9,
    format: "number",
    align: "right",
  },
  {
    dimension: REGISTERED_HOURS_COLUMN,
    title: (t: TFunction<"insights">) => t("hours.registered_hours_column_title"),
    sortable: true,
    isNumeric: true,
    width: size9,
    format: "number",
    align: "right",
  },
  {
    dimension: AVAILABLE_HOURS_COLUMNS,
    title: (t: TFunction<"insights">) => t("hours.availability_column_title"),
    sortable: true,
    isNumeric: true,
    width: size9,
    format: "number",
    align: "right",
  },
  {
    dimension: PRODUCTIVITY_COLUMN,
    title: (t: TFunction<"insights">) => t("hours.productivity_column_title"),
    sortable: true,
    isNumeric: true,
    width: size9,
    format: "percentage",
    align: "right",
  },
  {
    dimension: DECLARABILITY_COLUMN,
    title: (t: TFunction<"insights">) => t("hours.declarability_column_title"),
    sortable: true,
    isNumeric: true,
    width: size9,
    format: "percentage",
    align: "right",
  },
  {
    dimension: DECLARABLE_VALUE_COLUMN,
    title: (t: TFunction<"insights">) => t("hours.declarable_value_column_title"),
    sortable: true,
    isNumeric: true,
    width: size9,
    format: "currency",
    align: "right",
    enabled: true,
  },
];

const TARGET_URL = "/insights/dashboard/hours-details";
const NAVIGATE_MAPPING = [
  { recordKey: EMPLOYEE_IDENTIFIER_COLUMN, searchParam: "id" },
  { recordKey: EMPLOYEE_NAME_COLUMN, searchParam: "employeeName" },
];

export const HoursReportPage = () => {
  const { t } = useTranslation("insights");
  const onRecordSelect = useNavigateToDetailsPage(NAVIGATE_MAPPING, TARGET_URL);

  usePageTitle(t("hours.report_title"));

  const DEFAULT_COLUMNS_WITH_ID: Column[] = useMemo(() => {
    return COLUMNS_CONFIG.map((column) => {
      return {
        ...column,
        id: `${cubeDimensionToKey(column.dimension)}`,
        enabled: true,
      };
    });
  }, []);

  return (
    <Page>
      <CubeProvider>
        <Dashboard dashboardId={DASHBOARD_ID} query={QUERY} onRecordSelect={onRecordSelect}>
          <DashboardReportPageTemplate>
            <DashboardReportPageTemplate.Header>
              <DashboardReportPageTemplate.Filters>
                <FilterWidget dimensions={FILTER_CONFIG} />
              </DashboardReportPageTemplate.Filters>
              <DashboardReportPageTemplate.Settings>
                <ViewMenu dashboardId={DASHBOARD_ID} />
                <ViewActionsWidget columns={DEFAULT_COLUMNS_WITH_ID} />
              </DashboardReportPageTemplate.Settings>
            </DashboardReportPageTemplate.Header>

            <DashboardReportPageTemplate.KpiContainer>
              <DashboardReportPageTemplate.Kpi>
                <MultiKPIWidget title={t("hours.registered_kpi_title")} kpis={LEFT_KPI_CONFIG} />
              </DashboardReportPageTemplate.Kpi>
              <DashboardReportPageTemplate.Kpi>
                <TargetKPIWidget
                  title={t("hours.productivity_kpi_title")}
                  dimension={PRODUCTIVITY_COLUMN}
                  aggregation="average"
                />
              </DashboardReportPageTemplate.Kpi>
              <DashboardReportPageTemplate.Kpi>
                <TargetKPIWidget
                  title={t("hours.billability_kpi_title")}
                  dimension={DECLARABILITY_COLUMN}
                  aggregation="average"
                />
              </DashboardReportPageTemplate.Kpi>
              <DashboardReportPageTemplate.Kpi>
                <MultiKPIWidget title={t("hours.billable_kpi_title")} kpis={RIGHT_KPI_CONFIG} />
              </DashboardReportPageTemplate.Kpi>
            </DashboardReportPageTemplate.KpiContainer>
            <DashboardReportPageTemplate.Table>
              <TableWidget title={t("hours.employee_table_title")} columns={COLUMNS_CONFIG} />
            </DashboardReportPageTemplate.Table>
          </DashboardReportPageTemplate>
        </Dashboard>
      </CubeProvider>
    </Page>
  );
};
