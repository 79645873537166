import { skipToken } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Button, Datepicker, Footer, Page, PageHeader, RadioInput, Spinner } from "@simplicate/ui";
import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Planning, useGetSalesQuery } from "../../../data";
import styles from "./SalesPlanningPage.module.scss";
import { useSalesPlanningForm } from "./useSalesPlanningForm";

const defaultPlanningObject: Partial<Planning> = {
  useInResourcePlanner: false,
  expectedStartDate: null,
  expectedEndDate: null,
};

export const SalesPlanningPage = () => {
  const { t } = useTranslation("sales_planning");
  const navigate = useNavigate();
  const { id: saleId } = useParams<{ id: string }>();
  const { data } = useGetSalesQuery(/* istanbul ignore next */ saleId ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const dateStringToDate = useCallback(
    (dateString: string | null | undefined) => (dateString ? /* istanbul ignore next */ new Date(dateString) : null),
    [],
  );

  const planning: Planning | undefined = useMemo(() => data?.planning, [data]);
  const {
    values,
    errors,
    touched,
    onUseInResourcePlannerChange,
    onExpectedStartDateChange,
    onExpectedEndDateChange,
    handleSubmit,
    isSubmitting,
  } = useSalesPlanningForm({
    saleId,
    planning: /* istanbul ignore next */ planning ?? defaultPlanningObject,
    afterSubmitTarget: `/sales/${saleId}/planning`,
  });

  return (
    <Page
      testId="sales-planning-component"
      footer={
        <Footer>
          <Button type="submit" form="sales-planning-form" testId="submit-button">
            {t("buttons.save", { ns: "general" })}
          </Button>
          <Button onClick={() => navigate(-1)} variant="subtle" testId="cancel-button">
            {t("buttons.cancel", { ns: "general" })}
          </Button>
          {isSubmitting && (
            <div className={styles.isSubmittingContainer}>
              <Spinner testId="sale-planning-page-is-submitting-spinner" />
              <span className={styles.normalText}>{t("planning_is_being_submitted")}</span>
            </div>
          )}
        </Footer>
      }
    >
      <PageHeader>{t("planning_page_title")}</PageHeader>
      <form
        id="sales-planning-form"
        onSubmit={handleSubmit}
        data-testid="sales-planning-form"
        className={styles.formContainer}
      >
        <RadioInput
          value={values.useInResourcePlanner ? "yes" : "no"}
          options={[
            { label: t("yes", { ns: "general" }), value: "yes" },
            { label: t("no", { ns: "general" }), value: "no" },
          ]}
          name="isPlannable"
          onChange={(value: "no" | "yes") => void onUseInResourcePlannerChange(value)}
          direction="horizontal"
          label={t("enable_in_resource_planner")}
        />
        <div className={styles.dateContainer}>
          <Datepicker
            testId="expected-start-date"
            value={dateStringToDate(values.expectedStartDate)}
            name="expectedStartDate"
            placeholder={t("expected_start_date")}
            label={t("expected_start_date")}
            invalid={touched.expectedStartDate && errors.expectedStartDate !== undefined}
            readOnlyInput={false}
            onChange={/* istanbul ignore next */ (value) => void onExpectedStartDateChange(value)}
          />
          <Datepicker
            testId="expected-end-date"
            value={dateStringToDate(values.expectedEndDate)}
            name="expectedEndDate"
            placeholder={t("expected_end_date")}
            label={t("expected_end_date")}
            invalid={touched.expectedEndDate && errors.expectedEndDate !== undefined}
            readOnlyInput={false}
            onChange={/* istanbul ignore next */ (value) => void onExpectedEndDateChange(value)}
          />
        </div>
      </form>
    </Page>
  );
};
