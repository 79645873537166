import { useTranslation } from "@simplicate/translations";
import { useMemo } from "react";

const keys = [
  "account_settings",
  "accounting_integrations",
  "add_on_center_add_on",
  "add_on_center",
  "administration_settings",
  "api_settings",
  "bcc_settings",
  "crm",
  "custom_fields_settings",
  "document_settings",
  "dossier_settings",
  "hours_reporting",
  "hrm_reporting_absence",
  "hrm_reporting_leave",
  "hrm",
  "import_settings",
  "integrations_settings",
  "invoices",
  "mail_manager",
  "module_business_intelligence",
  "module_crm",
  "module_dashboard",
  "module_hours",
  "module_hrm",
  "module_insights",
  "module_invoicing",
  "module_projects",
  "module_resource_planner",
  "module_sales",
  "module_settings",
  "my_profile",
  "notifications_settings",
  "paperlayout_settings",
  "projects_reporting_combined",
  "projects_reporting_process",
  "projects_reporting_revenue",
  "projects",
  "reporting_invoicing",
  "reporting_mrr",
  "sales_reporting_funnel",
  "sales",
  "subscription_settings",
  "users_and_groups_settings",
] as const;

export type TitleKey = (typeof keys)[number];

export const useTitleTranslator = (title: string | undefined) => {
  const { t } = useTranslation("page_titles");
  const translatedTitle = useMemo(
    (): Record<TitleKey, string> => ({
      account_settings: t("settings.account_settings"),
      accounting_integrations: t("settings.accountancy_integrations"),
      add_on_center_add_on: t("settings.add_on_center_add_on"),
      add_on_center: t("settings.add_on_center"),
      administration_settings: t("settings.administration_settings"),
      api_settings: t("settings.api_settings"),
      bcc_settings: t("settings.bcc_settings"),
      crm: t("settings.crm_settings"),
      custom_fields_settings: t("settings.custom_fields_settings"),
      document_settings: t("settings.document_settings"),
      dossier_settings: t("settings.dossier_settings"),
      hours_reporting: t("hours.hours_reporting"),
      hrm_reporting_absence: t("hrm.hrm_reporting_absence"),
      hrm_reporting_leave: t("hrm.hrm_reporting_leave"),
      hrm: t("settings.hrm_settings"),
      import_settings: t("settings.import_settings"),
      integrations_settings: t("settings.integrations_settings"),
      invoices: t("settings.invoices_settings"),
      mail_manager: t("settings.mail_manager"),
      module_business_intelligence: t("modules.business_intelligence", { ns: "general" }),
      module_crm: t("modules.crm", { ns: "general" }),
      module_dashboard: t("modules.dashboard", { ns: "general" }),
      module_hours: t("modules.hours", { ns: "general" }),
      module_hrm: t("modules.hrm", { ns: "general" }),
      module_insights: t("modules.insights", { ns: "general" }),
      module_invoicing: t("modules.invoicing", { ns: "general" }),
      module_projects: t("modules.projects", { ns: "general" }),
      module_resource_planner: t("modules.resource_planner", { ns: "general" }),
      module_sales: t("modules.sales", { ns: "general" }),
      module_settings: t("modules.settings", { ns: "general" }),
      my_profile: t("dashboard.my_profile"),
      notifications_settings: t("settings.notifications_settings"),
      paperlayout_settings: t("settings.paperlayout_settings"),
      projects_reporting_combined: t("projects.projects_reporting_combined"),
      projects_reporting_process: t("projects.projects_reporting_process"),
      projects_reporting_revenue: t("projects.projects_reporting_revenue"),
      projects: t("settings.projects_settings"),
      reporting_invoicing: t("invoices.reporting_invoicing"),
      reporting_mrr: t("invoices.reporting_mrr"),
      sales_reporting_funnel: t("sales.sales_reporting_funnel"),
      sales: t("settings.sales_settings"),
      subscription_settings: t("settings.subscription_settings"),
      users_and_groups_settings: t("settings.users_and_groups_settings"),
    }),
    [t],
  );

  if (title === undefined || !keys.includes(title as TitleKey)) {
    return undefined;
  }

  return translatedTitle[title as TitleKey];
};
