import { simplicateApiV3 } from "../components";
import { createTagTypes } from "../tags";
import { type InvoiceMethod } from "./InvoiceMethod";

export type DefaultService = {
  id: string;
  description: string;
  invoiceMethod: InvoiceMethod;
};

const tags = createTagTypes({
  tagPrefix: "default_service",
  tags: ["list"],
});

const endpoints = simplicateApiV3.enhanceEndpoints({ addTagTypes: Object.values(tags) }).injectEndpoints({
  endpoints: (builder) => ({
    getDefaultServices: builder.query<DefaultService[], void>({
      query: () => ({
        url: "/defaultService.list",
      }),
      providesTags: [tags.list],
    }),
  }),
  overrideExisting: false,
});

export const { useGetDefaultServicesQuery } = endpoints;
