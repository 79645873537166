import { useTranslation } from "@simplicate/translations";
import { Datepicker, Tooltip } from "@simplicate/ui";
import { memo } from "react";

type TimeframeStartDatepickerProps = {
  value: Date | undefined;
  label: string;
  error: string | undefined;
  touched?: boolean;
  onChange: (date: Date | undefined) => void;
  disabled: boolean;
  disabledTooltip?: string;
};

export const TimeframeStartDatePicker = memo(function TimeframeStartDatePicker({
  error,
  touched = false,
  disabled,
  label,
  disabledTooltip,
  ...props
}: TimeframeStartDatepickerProps) {
  const { t } = useTranslation("project_services");

  const datepicker = (
    <Datepicker
      name="startDate"
      testId="start-date-datepicker"
      placeholder={t("choose_date")}
      label={label}
      invalid={touched && error !== undefined}
      disabled={disabled}
      showButtonBar
      readOnlyInput={false}
      {...props}
    />
  );

  return (
    <>
      {!disabledTooltip && datepicker}
      {disabled && disabledTooltip && (
        <Tooltip message={disabledTooltip} position="right">
          {datepicker}
        </Tooltip>
      )}
    </>
  );
});
