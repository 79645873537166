import { useTranslation } from "@simplicate/translations";
import { Button, Clickable, Dialog, DialogHandle, DialogResult, Icon, Input } from "@simplicate/ui";
import { forwardRef, useState, useImperativeHandle, useRef } from "react";
import styles from "./SaveViewDialog.module.scss";

export type SaveViewDialogHandle = {
  open: (defaultName?: string, options?: { dialogTitle?: string }) => Promise<DialogResult<{ name: string }>>;
};

export const SaveViewDialog = forwardRef<SaveViewDialogHandle>(function SaveViewDialog(_, ref) {
  const { t } = useTranslation("insights");
  const [name, setName] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const dialogRef = useRef<DialogHandle<{ name: string }>>(null);

  useImperativeHandle(ref, () => ({
    open: async (defaultName?: string, options?: { dialogTitle?: string }) => {
      setName(defaultName ?? "");

      if (options?.dialogTitle) {
        setTitle(options.dialogTitle);
      }

      // istanbul ignore else -- this guard exists for TS reasons
      if (dialogRef.current) {
        return dialogRef.current.open();
      }

      // istanbul ignore next -- this should never be reached
      return Promise.resolve({ status: "cancel" });
    },
  }));

  return (
    <Dialog ref={dialogRef}>
      <form className={styles.modalContent}>
        <Clickable type="reset" className={styles.closeButton}>
          <Icon icon="times" />
        </Clickable>
        <h3 className={styles.modalTitle}>
          <Icon icon="floppyDisk" />
          {title || t("general.view_modal_title")}
        </h3>
        <Input
          type="text"
          name="name"
          maxLength={191}
          label={t("general.view_name_label")}
          onChange={(e) => {
            setName(e.target.value);
          }}
          value={name}
        />
        <div className={styles.modalButtonsWrapper}>
          <Button variant="primary" size="medium" type="submit" disabled={!name}>
            {t("general.button_save")}
          </Button>
          <Button variant="subtle" size="medium" type="reset">
            {t("general.button_cancel")}
          </Button>
        </div>
      </form>
    </Dialog>
  );
});
