import { FeatureFlagSwitch } from "@simplicate/api-client";
import { DashboardPage } from "@simplicate/dashboard";
import { Navigate } from "react-router-dom";
import { IFrameEnvironment } from "../../components";
import { DashboardTabs, UserTabs } from "../../components/IFrameEnvironment/Tabs";
import { RedirectToFirstAccessiblePage } from "../components";
import { RouteObject } from "../types";

export const dashboardRoutes: RouteObject<"dossier">[] = [
  {
    path: "dashboard",
    element: <RedirectToFirstAccessiblePage />,
    handle: {
      headerTitle: "module_dashboard",
      theme: "dashboard",
      tabsComponent: DashboardTabs,
    },
    children: [
      {
        index: true,
        element: <Navigate to="my-tasks" replace />,
      },
      {
        path: "overview",
        element: (
          <FeatureFlagSwitch
            featureFlag="dashboard-landing-page"
            ifEnabled={<DashboardPage />}
            ifDisabled={<Navigate to="my-tasks" replace />}
          />
        ),
      },
      {
        path: "my-tasks",
        element: <IFrameEnvironment src="/v1/dashboard" />,
      },
      {
        path: "all-tasks",
        element: <IFrameEnvironment src="/v1/dashboard?tab=all_company_task" />,
        handle: {
          permissions: [
            {
              module_key: "dossier",
              right_key: "all_tasks",
            },
          ],
        },
      },
    ],
  },
  {
    path: "dashboard/user",
    handle: {
      headerTitle: "my_profile",
    },
    children: [
      {
        index: true,
        element: <Navigate to="basic-info" replace />,
        handle: { tabsComponent: UserTabs, backNavigationPath: "/dashboard" },
      },
      {
        path: "basic-info",
        element: <IFrameEnvironment src="/v1/dashboard/user?tab=basic_info" />,
        handle: { tabsComponent: UserTabs, backNavigationPath: "/dashboard" },
      },
      {
        path: "2fa",
        element: <IFrameEnvironment src="/v1/dashboard/user?tab=2fa" />,
        handle: { tabsComponent: UserTabs, backNavigationPath: "/dashboard" },
      },
      {
        path: "apps",
        element: <IFrameEnvironment src="/v1/dashboard/user?tab=apps" />,
        handle: { tabsComponent: UserTabs, backNavigationPath: "/dashboard" },
      },
      {
        path: "calendar-integration",
        element: <IFrameEnvironment src="/v1/dashboard/user?tab=calendar_integration" />,
        handle: { tabsComponent: UserTabs, backNavigationPath: "/dashboard" },
      },
      {
        path: "change-password",
        element: <IFrameEnvironment src="/v1/dashboard/user?tab=changepassword" />,
        handle: { tabsComponent: UserTabs, backNavigationPath: "/dashboard" },
      },
    ],
  },
];
