import { simplicateApiV3, createTagTypes } from "@simplicate/api-client";

type Dashboard = {
  name: string;
  slug: string;
  category: string;
  description: string;
};

type Category = {
  name: string;
  slug: string;
  description: string;
};

type DashboardList = {
  dashboards: Dashboard[];
  categories: Category[];
};

const dashboardTags = createTagTypes({
  tagPrefix: "Dashboard",
  tags: ["dashboard"],
});

const endpointsV3 = simplicateApiV3.enhanceEndpoints({ addTagTypes: Object.values(dashboardTags) }).injectEndpoints({
  endpoints: (builder) => ({
    getDashboardsList: builder.query<DashboardList, void>({
      query: () => ({
        url: `/insight.dashboards.list`,
      }),
      providesTags: [dashboardTags.dashboard],
    }),
  }),
});

export const { useGetDashboardsListQuery } = endpointsV3;
