import { createTagTypes, simplicateApiV3, type InvoiceMethod, type SubscriptionCycle } from "@simplicate/api-client";
import { CostType, HourTypeFromApi, InvoicePrice } from "../types";
import { MoneyFromAPI } from "../utils";

export type HourTypeInCreateServiceBody = {
  defaultHourTypeId: string;
  hourlyRate: MoneyFromAPI;
  amountOfHours: number;
  isInvoiceable: boolean | undefined;
};

export type CostTypeInCreateServiceBody = {
  id: string;
  defaultCostTypeId: string;
  description: string;
  purchasePrice: MoneyFromAPI;
  sellingPrice: MoneyFromAPI;
  quantity: number | undefined;
  isInvoiceable: boolean | undefined;
};

export type HourTypeConfigurationInBody = {
  hourTypes: HourTypeInCreateServiceBody[];
  employeeHourlyRates: object[];
  specifiedTotal: MoneyFromAPI;
};

type CostTypeConfigurationInBody = {
  costTypes: CostTypeInCreateServiceBody[];
};

export type BaseProjectServiceBody = {
  defaultServiceId: string;
  description: string;
  explanation: string | null;
  timeFrame: {
    startDate: string | null;
    endDate: string | null;
  } | null;
  revenueGroupId: string;
  vatCodeId: string;
  hoursRegistrationConfiguration?: HourTypeConfigurationInBody;
  costsRegistrationConfiguration?: CostTypeConfigurationInBody;
  isPlannable?: boolean;
};

type FixedPriceServiceBody = {
  invoiceMethod: InvoiceMethod.fixed_price;
  invoicePrice: InvoicePrice;
  invoiceableFrom: string | null;
  invoiceInInstallments: boolean;
};

type SubscriptionServiceBody = {
  invoiceMethod: InvoiceMethod.subscription;
  subscriptionCycle: SubscriptionCycle;
  invoicePrice: InvoicePrice;
  registrationTimeframe?: {
    startDate: string | null;
    endDate: string | null;
  } | null;
  invoiceTogetherWith?: string;
};

type TimeAndExpensesServiceBody = {
  invoiceMethod: InvoiceMethod.time_and_expenses;
};

export type ProjectServiceBody =
  | (BaseProjectServiceBody & FixedPriceServiceBody)
  | (BaseProjectServiceBody & SubscriptionServiceBody)
  | (BaseProjectServiceBody & TimeAndExpensesServiceBody);

export type CreateProjectServiceBody = ProjectServiceBody & {
  projectId: string;
};

export type EditProjectServiceBody = ProjectServiceBody & {
  id: string;
};

type BuildServiceBody = {
  defaultServiceId: string;
  projectId: string;
};

export type EmployeeHourlyRate = {
  employeeId: string;
  hourlyRate: MoneyFromAPI;
};

export type HourTypeInService = {
  defaultHourType: HourTypeFromApi;
  hourlyRate: MoneyFromAPI;
  amountOfHours: number;
  isInvoiceable: boolean | undefined;
  hasRegistrations: boolean;
};

export type CostTypeInService = {
  id: string;
  defaultCostType: CostType;
  description?: string;
  purchasePrice: MoneyFromAPI;
  sellingPrice: MoneyFromAPI;
  quantity: number;
  isInvoiceable?: boolean;
  margin: number;
  total: MoneyFromAPI;
  hasRegistrations: boolean;
};

type HourTypeConfigurationInService = {
  hourTypes: HourTypeInService[];
  hourTypeTotals?: {
    calculatedTotal: MoneyFromAPI;
    specifiedTotal: MoneyFromAPI;
    hoursBudget: number;
  };
  employeeHourlyRates?: EmployeeHourlyRate[];
  canRegisterHours: boolean;
};

type CostTypeConfigurationInService = {
  costTypes: CostTypeInService[];
  costTypeTotals?: {
    total: MoneyFromAPI;
    budget: MoneyFromAPI;
  };
  canRegisterCosts: boolean;
};

export type BaseProjectService = {
  projectId: string;
  timeFrame?: {
    startDate?: string;
    endDate?: string;
  };
  isPlannable?: boolean;
  hasAssignments: boolean;
  id: string;
  defaultServiceId: string;
  description?: string;
  explanation?: string;
  revenueGroupId: string;
  vatCodeId: string;
  hoursRegistrationConfiguration: HourTypeConfigurationInService;
  costsRegistrationConfiguration: CostTypeConfigurationInService;
  existsOnInvoice: boolean;
};

export type FixedPriceService = BaseProjectService & {
  invoiceMethod: InvoiceMethod.fixed_price;
  invoicePrice: InvoicePrice;
  invoiceInInstallments: boolean;
  invoiceableFrom?: string;
  hasInstallmentPlan: boolean;
};

export type SubscriptionService = BaseProjectService & {
  invoiceMethod: InvoiceMethod.subscription;
  subscriptionCycle: SubscriptionCycle;
  invoicePrice: InvoicePrice;
  registrationTimeframe?: {
    startDate: string | null;
    endDate: string | null;
  };
  invoicedUntil?: string;
  invoiceTogetherWith?: string;
};

export type TimeAndExpensesService = BaseProjectService & {
  invoiceMethod: InvoiceMethod.time_and_expenses;
};

export type ProjectService = FixedPriceService | SubscriptionService | TimeAndExpensesService;

export type BuiltProjectService = {
  defaultServiceId: string;
  description?: string;
  invoicePrice?: InvoicePrice;
  invoiceMethod: InvoiceMethod;
  revenueGroup?: {
    id: string;
    name: string;
  };
  vatCode?: {
    id: string;
    name: string;
  };
  useEmployeeHourlyRate: boolean;
  hoursRegistrationConfiguration: {
    hourTypes: HourTypeInService[];
    employeeHourlyRates: EmployeeHourlyRate[];
    hourTypeTotals?: {
      calculatedTotal: MoneyFromAPI;
      specifiedTotal: MoneyFromAPI;
      hoursBudget: number;
    };
    canRegisterHours: boolean;
  };
  costsRegistrationConfiguration: {
    costTypes: CostTypeInService[];
    canRegisterCosts: boolean;
    costTypeTotals?: {
      total: MoneyFromAPI;
      budget: MoneyFromAPI;
    };
  };
  isPlannable?: boolean;
};

export type ListSubscriptionsThatCanBeInvoicedTogetherBody = {
  currentServiceId?: string;
  projectId: string;
  startDate: string;
  subscriptionCycle: string;
};

export type SubscriptionThatCanBeInvoicedTogether = {
  id: string;
  description: string;
};

const tags = createTagTypes({
  tagPrefix: "ProjectService",
  tags: ["service"],
});

const endpoints = simplicateApiV3.enhanceEndpoints({ addTagTypes: Object.values(tags) }).injectEndpoints({
  endpoints: (builder) => ({
    buildProjectService: builder.query<BuiltProjectService, BuildServiceBody>({
      query: (body) => ({
        url: "/projectService.build",
        method: "POST",
        body,
      }),
    }),
    createProjectService: builder.mutation<ProjectService, CreateProjectServiceBody>({
      query: (projectService) => ({
        url: "/projectService.create",
        method: "POST",
        body: projectService,
      }),
    }),
    getProjectService: builder.query<ProjectService, string>({
      query: (serviceId) => ({
        url: `/projectService.get/${serviceId}`,
        method: "GET",
      }),
      providesTags: (_result, _error, serviceId) => [{ type: tags.service, id: serviceId }],
    }),
    editProjectService: builder.mutation<ProjectService, EditProjectServiceBody>({
      query: (body) => ({
        url: `/projectService.edit`,
        method: "POST",
        body,
      }),
      invalidatesTags: (_result, _error, body) => [{ type: tags.service, id: body.id }],
    }),
    listSubscriptionsThatCanBeInvoicedTogether: builder.query<
      SubscriptionThatCanBeInvoicedTogether[],
      ListSubscriptionsThatCanBeInvoicedTogetherBody
    >({
      query: (body) => ({
        url: `/projectService.listSubscriptionsThatCanBeInvoicedTogether`,
        method: "POST",
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useBuildProjectServiceQuery,
  useCreateProjectServiceMutation,
  useGetProjectServiceQuery,
  useEditProjectServiceMutation,
  useListSubscriptionsThatCanBeInvoicedTogetherQuery,
} = endpoints;
