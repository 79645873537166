import BigJs from "big.js";

export type Big = BigJs;
export const Big = BigJs;

export type MoneyFromAPI = {
  amount: string;
  currency: string;
};

export type Money = Omit<MoneyFromAPI, "amount"> & {
  amount: Big;
};

type OptionalMoney = Omit<MoneyFromAPI, "amount"> & {
  amount: Big | undefined;
};

function moneyStringToBig(money: string): Big {
  return Big(money);
}

export const transformMoneyFromAPIToMoney = (money: MoneyFromAPI): Money => ({
  amount: moneyStringToBig(money.amount),
  currency: money.currency,
});

const ROUND_HALF_UP = 1;

export const transformMoneyToMoneyFromAPI = ({ amount = Big(0), currency }: OptionalMoney): MoneyFromAPI => ({
  amount: amount.toFixed(2, ROUND_HALF_UP),
  currency,
});
