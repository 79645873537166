import { useTranslation } from "@simplicate/translations";
import { Icon, RadioInput, Tooltip } from "@simplicate/ui";
import classNames from "classnames";
import { memo } from "react";
import styles from "./IsPlannableRadioButton.module.scss";

type IsPlannableRadioButtonProps = {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  tooltip?: string;
};

export const IsPlannableRadioButton = memo(function IsPlannableRadioButton({
  value,
  onChange,
  disabled,
  tooltip,
}: IsPlannableRadioButtonProps) {
  const { t } = useTranslation("project_services");

  const radioButton = (
    <RadioInput
      value={value ? "yes" : "no"}
      options={[
        { label: t("yes", { ns: "general" }), value: "yes", disabled },
        { label: t("no", { ns: "general" }), value: "no", disabled },
      ]}
      name="is_plannable"
      onChange={(value) => onChange(value === "yes")}
      direction="horizontal"
    />
  );

  return (
    <div className={styles.isPlannableContainer} data-testid="is-plannable-radiobutton">
      <label
        htmlFor="is_plannable"
        className={classNames(styles.isPlannableLabelContainer, disabled && styles.disabled)}
      >
        <span className={styles.isPlannableLabel}>{t("is_plannable_label")}</span>
        <Tooltip message={t("is_plannable_tooltip")}>
          <div className={styles.infoIconContainer}>
            <Icon icon="infoCircle" className={styles.isPlannableLabelInfoIcon} />
          </div>
        </Tooltip>
      </label>
      <div className={styles.isPlannableRadioButton}>
        {tooltip ? (
          <Tooltip message={tooltip} position="right">
            {radioButton}
          </Tooltip>
        ) : (
          radioButton
        )}
      </div>
    </div>
  );
});
