import { RenderParams } from "@minoru/react-dnd-treeview";
import { useTranslation } from "@simplicate/translations";
import { Portal, usePortalContext, MODAL_ANCHOR } from "@simplicate/ui";
import { useState, useMemo, useRef, useEffect, forwardRef } from "react";
import { useParams } from "react-router-dom";
import { ServiceGroupNodeModel, ServiceGroupType } from "../../GroupedServicesManager.types";
import { EmptyGroupMessage } from "../EmptyGroupMessage";
import { LoadingAnimation } from "../LoadingAnimation";
import { ServiceGroupFooter } from "../ServiceGroupFooter";
import { ServiceGroupHeader } from "../ServiceGroupHeader";
import { ServiceRowHeader } from "../ServiceRowHeader";
import styles from "./ServiceGroup.module.scss";

type ServiceGroupProps = Partial<RenderParams> & {
  node: ServiceGroupNodeModel;
  dropTargetId?: number | string;
  draggedElementId?: number | string;
};

const getLoadingEntry = (loadingMutation: Record<string, boolean> | undefined) => {
  return loadingMutation ? Object.entries(loadingMutation).find(([, value]) => value === true) : undefined;
};

// eslint-disable-next-line complexity -- todo: refactor
export const ServiceGroup = forwardRef<HTMLDivElement, ServiceGroupProps>(function ServiceGroup(
  { node: { text, id, data }, hasChild, isDropTarget, dropTargetId, draggedElementId },
  handleRef,
) {
  /*istanbul ignore next*/
  const isUngroupedContainer = data?.groupType === ServiceGroupType.UNGROUPED;
  const { t } = useTranslation("grouped_services_manager");
  const { id: saleId } = useParams<{ id: string }>();

  const [deletedGroupId, setDeletedGroupId] = useState<number | string>("");

  const { targetRefs } = usePortalContext();

  const linkTo = isUngroupedContainer
    ? `/sales/${saleId}/services/new`
    : `/sales/${saleId}/services/new?serviceGroupId=${id}`;

  const { loadingText, selectedGroupId } = useMemo(() => {
    let newLoadingText = "";
    let newSelectedGroupId: number | string = "";

    const loadingEntry = getLoadingEntry(data?.loadingMutation);

    const [loadingKey] = loadingEntry ?? [];

    if (loadingKey === "isDeleteServiceGroupLoading") {
      newLoadingText = data?.loadingText ?? "";
      newSelectedGroupId = deletedGroupId;
    } else if (loadingKey === "isChangeServiceTreeLoading") {
      if (dropTargetId === undefined) {
        newLoadingText = data?.loadingText ?? "";
        newSelectedGroupId = draggedElementId ?? "";
      } else {
        newLoadingText = t("loading_messages.change_service_location");
        newSelectedGroupId = dropTargetId ?? "";
      }
    }

    return { loadingText: newLoadingText, selectedGroupId: newSelectedGroupId };
  }, [draggedElementId, dropTargetId, deletedGroupId, data?.loadingMutation, data?.loadingText, t]);

  const handleDeleteGroup = (shouldDeleteServices: boolean) => {
    setDeletedGroupId(id);
    data?.deleteCallback?.(shouldDeleteServices);
  };

  const editServiceGroup = () => {
    data?.editCallback?.();
  };

  const shouldShowGroupDraggedLoading = deletedGroupId !== id && dropTargetId === undefined && selectedGroupId === id;
  const shouldShowGroupLoading = loadingText && selectedGroupId === id;

  const { setTargetRef } = usePortalContext();
  const groupRef = useRef(null);

  useEffect(() => {
    // istanbul ignore else -- this guard exists to keep TS happy, but it should never fail
    if (groupRef.current) {
      return setTargetRef(id.toString(), groupRef);
    }
  }, [setTargetRef, id]);

  return isUngroupedContainer ? (
    <>
      <ServiceRowHeader />
      {!hasChild && <EmptyGroupMessage isDropTarget={isDropTarget} linkTo={linkTo} inUngroupedContainer={true} />}
    </>
  ) : (
    <>
      {shouldShowGroupDraggedLoading && (
        <Portal targetRef={targetRefs[MODAL_ANCHOR]}>
          <LoadingAnimation text={loadingText} type="group-drag" />
        </Portal>
      )}
      {!shouldShowGroupDraggedLoading && shouldShowGroupLoading && <LoadingAnimation text={loadingText} />}
      <div className={styles.serviceGroup}>
        <div ref={groupRef} />
        <ServiceGroupHeader
          serviceGroupId={id.toString()}
          text={text}
          description={data?.description}
          groupLabel={data?.type}
          editCallback={editServiceGroup}
          deleteCallback={handleDeleteGroup}
          ref={handleRef}
        />
        <ServiceRowHeader />
        {!hasChild && <EmptyGroupMessage isDropTarget={isDropTarget} linkTo={linkTo} />}
      </div>
      {hasChild && <ServiceGroupFooter total={data?.totals?.totalExcluding} />}
    </>
  );
});
