import { useMemo } from "react";
import { useGetFeatureFlagsQuery } from "../../endpoints/FeatureFlag";

// eslint-disable-next-line @typescript-eslint/no-unused-vars -- used as type
const supportedFlags = [
  "app-native-v2-application-settings",
  "business-intelligence",
  "dashboard-landing-page",
  "hours-enforce-register-minimum-interval",
  "hours-hide-tab-register-costs-old",
  "hours-hide-tab-register-hours-agenda-old",
  "hours-hide-tab-register-hours-timesheet-old",
  "hours-hide-tab-register-mileage-old",
  "hours-review-show-tab",
  "insights-end-user-access",
  "insights",
  "integrations-external-events-optional-sync",
  "integrations-mollie",
  "invoicing-edit-sent-invoice-services-text",
  "invoicing-hours-external-reference",
  "invoicing-refactor-terms-integrity",
  "invoicing-send-invoice-integrity-check",
  "invoicing-use-divergent-reminder-email",
  "notifications-crm-birthday-today",
  "projects-new-service-ui-subscriptions",
  "projects-new-service-ui-tariffs",
  "projects-new-service-ui",
  "resource-planner",
  "resource-planner-allow-planning-on-sales",
  "sales-service-groups-optional-services",
  "sales-service-groups",
  "system-add-on-center",
  "system-error-reporting-browser-datadog",
  "system-product-analytics-browser-posthog",
] as const;

export type SupportedFlags = (typeof supportedFlags)[number];

export const useFeatureFlag = (flag: SupportedFlags) => {
  const { data, isFetching } = useGetFeatureFlagsQuery();

  const enabled = useMemo(() => {
    if (!data) return false;

    const requestedFlag = data.data?.find(({ identifier }) => identifier === flag);

    return requestedFlag?.is_enabled ?? false;
  }, [data, flag]);

  return { enabled, isFetching: isFetching ?? false };
};
