import { useFeatureFlag, useLicenseGuard, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { TabBar, Tab } from "@simplicate/ui";
import { IFrameActionButtons } from "../../IFrameActionButtons";

export const DashboardTabs = () => {
  const { t } = useTranslation("tab_bar");

  const { enabled: hasWorkflowsEnabled } = useLicenseGuard("workflows");
  const hasAllWorkflowsPermission = usePermission("dossier", "all_tasks").granted;
  const { enabled: hasDashboardLandingPage } = useFeatureFlag("dashboard-landing-page");

  if (!hasWorkflowsEnabled) {
    return null;
  }

  return (
    <TabBar testId="dashboard" actions={<IFrameActionButtons />}>
      {hasDashboardLandingPage && <Tab href="/dashboard/overview" title={t("dashboard_landing_page")} />}
      <Tab href="/dashboard/my-tasks" title={t("my-task")} />
      {hasAllWorkflowsPermission && <Tab href="/dashboard/all-tasks" title={t("all-tasks")} />}
    </TabBar>
  );
};
