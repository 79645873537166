import { useTranslation } from "@simplicate/translations";
import { DialogResult, Icon, Link } from "@simplicate/ui";
import { useCallback } from "react";
import { FilterPreset, useUpdateFilterPresetMutation } from "../../data";
import { useDashboardContext } from "../Dashboard";

type EditNameActionProps = {
  view?: FilterPreset;
  askForName: (initialValue?: string, options?: { dialogTitle?: string }) => Promise<DialogResult<{ name: string }>>;
};

export const EditNameAction = ({ askForName, view }: EditNameActionProps) => {
  const { t } = useTranslation("insights");
  const {
    actions: { setView },
  } = useDashboardContext();

  const [updateFilterPreset] = useUpdateFilterPresetMutation();

  const handleClick = useCallback(() => {
    // istanbul ignore if -- this is a guard to keep TS happy.
    if (view === undefined) {
      return;
    }

    askForName(view.name, { dialogTitle: t("general.edit_view") })
      .then((result) => {
        if (result.status === "submit" && result.data) {
          setView({
            ...view,
            name: result.data.name,
          });
          void updateFilterPreset({
            id: view.id,
            name: result.data.name,
          });
        }
      })
      .catch(console.error);
  }, [askForName, setView, t, updateFilterPreset, view]);

  return (
    <Link testId="edit-view-button" onClick={handleClick} disabled={view === undefined}>
      <Icon icon="pen" fixedWidth />
      {t("general.edit_view")}
    </Link>
  );
};
