import { FormikTouched, FormikErrors } from "formik";
import type { ProjectServiceForm } from "../types";

export function isAnyDefaultServiceFieldTouched(touched: FormikTouched<ProjectServiceForm>) {
  return (
    touched.invoiceMethod ??
    touched.explanation ??
    touched.revenueGroup ??
    touched.vatCode ??
    touched.canRegisterHours ??
    touched.canRegisterCosts ??
    touched.hourTypes ??
    touched.costTypes ??
    touched.hourTypesSpecifiedTotal ??
    touched.invoicePrice ??
    touched.description ??
    false
  );
}

export async function resetTouchedOnRelevantFields(
  setFieldTouched: (field: string, touched: boolean) => Promise<FormikErrors<ProjectServiceForm>> | Promise<void>,
) {
  await Promise.all([
    setFieldTouched("invoiceMethod", false),
    setFieldTouched("description", false),
    setFieldTouched("explanation", false),
    setFieldTouched("revenueGroup", false),
    setFieldTouched("vatCode", false),
    setFieldTouched("canRegisterHours", false),
    setFieldTouched("canRegisterCosts", false),
    setFieldTouched("hourTypes", false),
    setFieldTouched("costTypes", false),
    setFieldTouched("hourTypesSpecifiedTotal", false),
    setFieldTouched("invoicePrice", false),
  ]);
}

export const scrollToError = (errors: FormikErrors<ProjectServiceForm>, formRef: React.RefObject<HTMLElement>) => {
  for (const [name, error] of Object.entries(errors)) {
    let selector;
    if (typeof error === "object") {
      const errorObject = error as Record<string, unknown>;

      selector = `[name="${Object.keys(errorObject).at(0)}"]`;
    } else {
      selector = `[name="${name}"]`;
    }

    const element = formRef?.current?.querySelector(selector);

    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });

      break;
    }
  }
};
