/* eslint-disable sonarjs/void-use -- TODO: refactor  */
import {
  type TagDescription,
  InvoiceMethod,
  SubscriptionCycle,
  invalidateTagsV2,
  projectTags,
  useFeatureFlag,
} from "@simplicate/api-client";
import { useAppDispatch } from "@simplicate/state";
import { useTranslation } from "@simplicate/translations";
import { showToast, type DialogHandle } from "@simplicate/ui";
import { useFormik, FormikErrors } from "formik";
import { type RefObject, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  type BuiltProjectService,
  useCreateProjectServiceMutation,
  useEditProjectServiceMutation,
  transformMoneyFromAPIToMoney,
  Big,
} from "../../../data";
import { setMargin, setPurchasePrice, setQuantity, setSellingPrice } from "../costTypeInFormUtils";
import { buildValidationSchema } from "../projectServiceFormValidation";
import { transformCostTypeInServiceToCostTypeInForm } from "../transformCostTypes";
import {
  transformFormToCreateProjectServiceBody,
  transformFormToEditProjectServiceBody,
} from "../transformFormToProjectServiceBody";
import { transformEmployeeHourlyRatesInForm, transformToHourTypeInForm } from "../transformHourTypes";
import { useBuildProjectService } from "./useBuildProjectService";
import { isAnyDefaultServiceFieldTouched, resetTouchedOnRelevantFields } from "./useProjectServiceFormHelpers";
import type { DefaultServiceDialogForm } from "../ApplyChangedDefaultServiceValuesDialog";
import type { ProjectServiceForm, ValidProjectServiceForm, HourTypeInForm, CostTypeInForm } from "../types";

interface ProjectServiceFormErrors extends FormikErrors<ProjectServiceForm> {
  costsOrHours?: string;
}

type UseProjectServiceFormProps = {
  initialValues?: ProjectServiceForm;
  projectId?: string;
  dialogRef: RefObject<DialogHandle<DefaultServiceDialogForm>>;
  afterSubmitTarget?: string;
  serviceId?: string;
  subscriptionsEnabled?: boolean;
};

const INITIAL_VALUES: ProjectServiceForm = {
  defaultService: undefined,
  invoiceMethod: undefined,
  description: undefined,
  explanation: undefined,
  timeframe: {
    startDate: undefined,
    endDate: undefined,
  },
  revenueGroup: undefined,
  vatCode: undefined,
  invoiceableFrom: undefined,
  invoiceInInstallments: undefined,
  canRegisterHours: undefined,
  canRegisterCosts: undefined,
  isPlannable: undefined,
  hourTypes: [],
  costTypes: [],
  hasInstallmentPlan: false,
  hasAssignments: false,
  registrationTimeframe: {
    startDate: undefined,
    endDate: undefined,
  },
  hasRegistrationTimeframe: false,
};

export type UseProjectServiceFormReturnType = ReturnType<typeof useProjectServiceForm>;

export const useProjectServiceForm = ({
  initialValues = INITIAL_VALUES,
  projectId,
  dialogRef,
  afterSubmitTarget = "",
  serviceId,
}: UseProjectServiceFormProps) => {
  const validationSchema = buildValidationSchema();
  const hasResourcePlanner = useFeatureFlag("resource-planner").enabled;
  const isEditingService = serviceId !== undefined;

  // TODO: SIM-38478 remove when subscriptions are fully implemented
  const { enabled: subscriptionsEnabled } = useFeatureFlag("projects-new-service-ui-subscriptions");

  const { t } = useTranslation("project_services");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  /* istanbul ignore next -- RTK is mocked in tests */
  const [createProjectService, { isSuccess: isCreateSuccess, isError: isCreateError }] =
    useCreateProjectServiceMutation({
      selectFromResult: ({ isSuccess, isError }) => ({ isSuccess, isError }),
    });

  const [editProjectService, { isSuccess: isEditSuccess, isError: isEditError }] = useEditProjectServiceMutation({
    selectFromResult: ({ isSuccess, isError }) => ({ isSuccess, isError }),
  });

  const isError = isCreateError || isEditError;
  const isSuccess = isCreateSuccess || isEditSuccess;

  useEffect(() => {
    if (!isError) return;

    showToast({ message: t("error_save"), type: "error" });
  }, [isError, t]);

  useEffect(() => {
    if (!isSuccess) return;

    navigate(afterSubmitTarget);
  }, [isSuccess, navigate, afterSubmitTarget]);

  const { values, errors, touched, setFieldValue, setFieldTouched, handleSubmit, setErrors, isSubmitting } =
    useFormik<ProjectServiceForm>({
      initialValues: {
        ...initialValues,
        timeframe: initialValues.timeframe,
        isPlannable: initialValues.isPlannable ?? true,
      },
      onSubmit: (values) => {
        if (!projectId) {
          return;
        }

        dispatch(invalidateTagsV2([projectTags.services as TagDescription<"HEADER_DATA" | "LOCALE_DATA">]));

        const body = {
          ...values,
          invoiceTogetherWith: values.invoiceTogetherWith,
        };

        if (isEditingService) {
          return editProjectService(
            transformFormToEditProjectServiceBody(body as ValidProjectServiceForm, serviceId, hasResourcePlanner),
          );
        }

        return createProjectService(
          transformFormToCreateProjectServiceBody(body as ValidProjectServiceForm, projectId, hasResourcePlanner),
        );
      },
      validationSchema,
      enableReinitialize: true,
    });

  const setFieldValueAndHandleErrors = useCallback(
    async (fieldName: string, fieldValue: unknown) => {
      const errors = await setFieldValue(fieldName, fieldValue);

      if (errors) {
        setErrors(errors);
      }
    },
    [setErrors, setFieldValue],
  );

  const setValue = useCallback(
    <ValueType>(fieldName: string, fieldValue: ValueType) => {
      void setFieldTouched(fieldName);
      void setFieldValueAndHandleErrors(fieldName, fieldValue);
    },
    [setFieldTouched, setFieldValueAndHandleErrors],
  );

  type SetFieldTouched = typeof setFieldTouched;

  const handleDialogInteraction = useCallback(
    async (
      dialogRef: RefObject<DialogHandle<DefaultServiceDialogForm>>,
      setFieldValueAndHandleErrors: (fieldName: string, fieldValue: unknown) => Promise<void>,
      setFieldTouched: SetFieldTouched,
      defaultService: string,
    ) => {
      const { current } = dialogRef;

      if (!current) return;

      const { data, status } = await current.open();

      if (status === "cancel") return;

      if (data?.defaultServiceChanged === "discard-touched") {
        await resetTouchedOnRelevantFields(setFieldTouched); // default service is not reset
      }
      await setFieldValueAndHandleErrors("defaultService", defaultService);
    },
    [],
  );

  const setDefaultService = useCallback(
    (defaultService: string) => {
      void setFieldTouched("defaultService");

      if (isEditingService || (values.defaultService !== undefined && isAnyDefaultServiceFieldTouched(touched))) {
        void handleDialogInteraction(dialogRef, setFieldValueAndHandleErrors, setFieldTouched, defaultService);
      } else {
        void setFieldValueAndHandleErrors("defaultService", defaultService);
      }
    },
    [
      values.defaultService,
      touched,
      setFieldTouched,
      setFieldValueAndHandleErrors,
      handleDialogInteraction,
      dialogRef,
      isEditingService,
    ],
  );

  const setInvoiceMethod = useCallback(
    (invoiceMethod: InvoiceMethod) => {
      /* istanbul ignore next -- This is a temporary check until subscriptions are fully implemented */
      if (!subscriptionsEnabled && invoiceMethod === InvoiceMethod.subscription) return;
      void setFieldTouched("invoiceMethod");
      void setFieldValueAndHandleErrors("invoiceMethod", invoiceMethod);
    },
    [setFieldTouched, setFieldValueAndHandleErrors, subscriptionsEnabled],
  );

  const setHourTypesTotal = useCallback(
    (value: Big | undefined) => {
      void setFieldTouched("hourTypesSpecifiedTotal");
      void setFieldValueAndHandleErrors("hourTypesSpecifiedTotal", {
        ...values.hourTypesSpecifiedTotal,
        amount: value,
      });
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.hourTypesSpecifiedTotal],
  );

  const addNewHourTypeEntry = useCallback(() => {
    void setFieldValueAndHandleErrors("hourTypes", [
      ...(values.hourTypes ?? []),
      {
        id: "",
        name: "",
        amount: 0,
        hourlyRate: { amount: Big(0), currency: "EUR" },
        total: { amount: Big(0), currency: "EUR" },
        isNewEntry: true,
        hasRegistrations: false,
      } satisfies HourTypeInForm,
    ]);
  }, [setFieldValueAndHandleErrors, values.hourTypes]);

  const removeNewHourTypeEntry = useCallback(() => {
    void setFieldValueAndHandleErrors("hourTypes", [
      ...(values.hourTypes ?? []).filter((hourType) => !hourType.isNewEntry),
    ]);
  }, [setFieldValueAndHandleErrors, values.hourTypes]);

  const addHourType = useCallback(
    (hourType: HourTypeInForm) => {
      void setFieldTouched("hourTypes");
      void setFieldValueAndHandleErrors("hourTypes", [
        ...(values.hourTypes ?? []).filter((hourType) => !hourType.isNewEntry),
        hourType,
      ]);
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.hourTypes],
  );

  const removeHourTypes = useCallback(
    (ids: string[]) => {
      void setFieldTouched("hourTypes");
      void setFieldValueAndHandleErrors(
        "hourTypes",
        values.hourTypes?.filter((hourType) => !ids.includes(hourType.id)),
      );
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.hourTypes],
  );

  const setAmountForHourType = useCallback(
    (hourTypeId: string, amount: number | undefined) => {
      void setFieldTouched("hourTypes");
      void setFieldValueAndHandleErrors(
        "hourTypes",
        values.hourTypes?.map((hourType) =>
          hourType.id === hourTypeId
            ? {
                ...hourType,
                amount,
                total: { ...hourType.total, amount: hourType.hourlyRate?.amount?.mul(amount ?? 0) ?? Big(0) },
              }
            : hourType,
        ),
      );
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.hourTypes],
  );

  const setHourlyRateForHourType = useCallback(
    (hourTypeId: string, hourlyRateAmount: Big | undefined) => {
      void setFieldTouched("hourTypes");
      void setFieldValueAndHandleErrors(
        "hourTypes",
        values.hourTypes?.map((hourType) =>
          hourType.id === hourTypeId
            ? {
                ...hourType,
                hourlyRate: hourlyRateAmount ? { ...hourType.hourlyRate, amount: hourlyRateAmount } : undefined,
                total: { ...hourType.total, amount: hourlyRateAmount?.mul(hourType.amount ?? 0) ?? Big(0) },
              }
            : hourType,
        ),
      );
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.hourTypes],
  );

  const toggleIsInvoiceableForHourType = useCallback(
    (hourTypeId: string) => {
      void setFieldTouched("hourTypes");
      void setFieldValueAndHandleErrors(
        "hourTypes",
        values.hourTypes?.map((hourType) =>
          hourType.id === hourTypeId
            ? {
                ...hourType,
                isInvoiceable: !hourType.isInvoiceable,
              }
            : hourType,
        ),
      );
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.hourTypes],
  );

  const toggleIsInvoiceableForCostType = useCallback(
    (costTypeId: string) => {
      void setFieldTouched("costTypes");
      void setFieldValueAndHandleErrors(
        "costTypes",
        values.costTypes?.map((costType) =>
          costType.id === costTypeId
            ? {
                ...costType,
                isInvoiceable: !costType.isInvoiceable,
              }
            : costType,
        ),
      );
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.costTypes],
  );

  const addCostType = useCallback(
    (costType: CostTypeInForm) => {
      void setFieldTouched("costTypes");
      // istanbul ignore next -- In real world scenario, this will never be undefined
      void setFieldValueAndHandleErrors("costTypes", [...(values.costTypes ?? []), costType]);
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.costTypes],
  );

  const removeCostType = useCallback(
    (id: string) => {
      void setFieldTouched("costTypes");
      void setFieldValueAndHandleErrors("costTypes", values.costTypes?.filter((costType) => costType.id !== id) ?? []);
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.costTypes],
  );

  const setLabelForCostType = useCallback(
    (costTypeId: string, label: string) => {
      void setFieldTouched("costTypes");
      void setFieldValueAndHandleErrors(
        "costTypes",
        values.costTypes?.map((costType) => (costType.id === costTypeId ? { ...costType, name: label } : costType)),
      );
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.costTypes],
  );

  const setQuantityForCostType = useCallback(
    (costTypeId: string, quantity: number | undefined) => {
      void setFieldTouched("costTypes");
      void setFieldValueAndHandleErrors(
        "costTypes",
        values.costTypes?.map((costType) => (costType.id === costTypeId ? setQuantity(costType, quantity) : costType)),
      );
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.costTypes],
  );

  const setPurchasePriceForCostType = useCallback(
    (costTypeId: string, purchasePriceAmount: Big | undefined) => {
      void setFieldTouched("costTypes");
      void setFieldValueAndHandleErrors(
        "costTypes",
        values.costTypes?.map((costType) =>
          costType.id === costTypeId ? setPurchasePrice(costType, purchasePriceAmount) : costType,
        ),
      );
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.costTypes],
  );

  const setMarginForCostType = useCallback(
    (costTypeId: string, margin: number | undefined) => {
      void setFieldTouched("costTypes");
      void setFieldValueAndHandleErrors(
        "costTypes",
        values.costTypes?.map((costType) => (costType.id === costTypeId ? setMargin(costType, margin) : costType)),
      );
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.costTypes],
  );

  const setSellingPriceForCostType = useCallback(
    (costTypeId: string, sellingPriceAmount: Big | undefined) => {
      void setFieldTouched("costTypes");
      void setFieldValueAndHandleErrors(
        "costTypes",
        values.costTypes?.map((costType) =>
          costType.id === costTypeId ? setSellingPrice(costType, sellingPriceAmount) : costType,
        ),
      );
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.costTypes],
  );

  const setSubscriptionCycle = useCallback(
    (value: SubscriptionCycle) => setValue("subscriptionCycle", value),
    [setValue],
  );
  const setTimeframeStartDate = useCallback(
    (value: Date | undefined) => setValue("timeframe.startDate", value),
    [setValue],
  );
  const setTimeframeEndDate = useCallback(
    (value: Date | undefined) => setValue("timeframe.endDate", value),
    [setValue],
  );
  const setDescription = useCallback((value: string) => setValue("description", value), [setValue]);
  const setExplanation = useCallback((value: string) => setValue("explanation", value), [setValue]);
  const setRevenueGroup = useCallback((value: string) => setValue("revenueGroup", value), [setValue]);
  const setVatCode = useCallback((value: string) => setValue("vatCode", value), [setValue]);
  const setHasRegistrationTimeframe = useCallback(
    (value: boolean) => setValue("hasRegistrationTimeframe", value),
    [setValue],
  );
  const setRegistrationTimeframeStartDate = useCallback(
    (value: Date | undefined) => setValue("registrationTimeframe.startDate", value),
    [setValue],
  );
  const setRegistrationTimeframeEndDate = useCallback(
    (value: Date | undefined) => setValue("registrationTimeframe.endDate", value),
    [setValue],
  );
  const setCanRegisterHours = useCallback((value: boolean) => setValue("canRegisterHours", value), [setValue]);
  const setIsPlannable = useCallback((value: boolean) => setValue("isPlannable", value), [setValue]);
  const setCanRegisterCosts = useCallback((value: boolean) => setValue("canRegisterCosts", value), [setValue]);
  const setInvoiceableFrom = useCallback((value: Date | undefined) => setValue("invoiceableFrom", value), [setValue]);
  const setInvoiceInInstallments = useCallback(
    (value: boolean) => setValue("invoiceInInstallments", value),
    [setValue],
  );
  const setInvoicePrice = useCallback(
    (value: Big | undefined) => setValue("invoicePrice", { ...values.invoicePrice, amount: value }),
    [setValue, values.invoicePrice],
  );

  const setInvoiceTogetherWith = useCallback(
    (value: string | undefined) => setValue("invoiceTogetherWith", value),
    [setValue],
  );

  const setCanInvoiceTogetherWith = useCallback(
    (value: boolean) => setValue("canInvoiceTogetherWith", value),
    [setValue],
  );
  const setInvoiceQuantity = useCallback((value: number | undefined) => setValue("invoiceQuantity", value), [setValue]);

  const setHourlyRateForEmployee = useCallback(
    (EmployeeId: string, hourlyRate: Big) => {
      void setFieldTouched("employeeHourlyRates");
      void setFieldValueAndHandleErrors(
        "employeeHourlyRates",
        values.employeeHourlyRates?.map((employee) => {
          if (employee.employeeId !== EmployeeId) return employee;

          return { ...employee, hourlyRate: { ...employee.hourlyRate, amount: hourlyRate } };
        }),
      );
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.employeeHourlyRates],
  );

  const setProjectServiceValues = useCallback(
    ({
      description,
      hoursRegistrationConfiguration,
      costsRegistrationConfiguration,
      isPlannable,
    }: BuiltProjectService) => {
      if (!touched.description) void setFieldValueAndHandleErrors("description", description);
      if (!touched.isPlannable && !values.hasAssignments)
        void setFieldValueAndHandleErrors("isPlannable", isPlannable ?? false);
      if (!touched.canRegisterHours)
        void setFieldValueAndHandleErrors("canRegisterHours", hoursRegistrationConfiguration.canRegisterHours);
      if (!touched.canRegisterCosts)
        void setFieldValueAndHandleErrors("canRegisterCosts", costsRegistrationConfiguration.canRegisterCosts);

      if (!touched.employeeHourlyRates) {
        void setFieldValueAndHandleErrors(
          "employeeHourlyRates",
          transformEmployeeHourlyRatesInForm(hoursRegistrationConfiguration.employeeHourlyRates),
        );
      }
    },
    [
      touched.description,
      touched.isPlannable,
      touched.canRegisterHours,
      touched.canRegisterCosts,
      setFieldValueAndHandleErrors,
      values.hasAssignments,
      touched.employeeHourlyRates,
    ],
  );
  const setProjectServiceHourAndCostValues = useCallback(
    ({
      hoursRegistrationConfiguration,
      costsRegistrationConfiguration,
      hoursRegistrationConfiguration: { hourTypes },
    }: BuiltProjectService) => {
      if (!touched.hourTypes && !touched.hourTypesSpecifiedTotal) {
        void setFieldValueAndHandleErrors(
          "hourTypes",
          hourTypes.map((hourTypeInService) => transformToHourTypeInForm(hourTypeInService)),
        );
        if (hoursRegistrationConfiguration.hourTypeTotals) {
          void setFieldValueAndHandleErrors(
            "hourTypesSpecifiedTotal",
            transformMoneyFromAPIToMoney(hoursRegistrationConfiguration.hourTypeTotals.specifiedTotal),
          );
        }
      }
      if (!touched.costTypes) {
        void setFieldValueAndHandleErrors(
          "costTypes",
          costsRegistrationConfiguration.costTypes.map((costTypeInService) =>
            transformCostTypeInServiceToCostTypeInForm(costTypeInService),
          ),
        );
      }
    },
    [touched.hourTypes, touched.hourTypesSpecifiedTotal, touched.costTypes, setFieldValueAndHandleErrors],
  );

  const setProjectServiceInvoiceValues = useCallback(
    ({ revenueGroup, vatCode, invoicePrice }: BuiltProjectService) => {
      if (values.existsOnInvoice) return;
      if (!touched.revenueGroup) void setFieldValueAndHandleErrors("revenueGroup", revenueGroup?.id);
      if (!touched.vatCode) void setFieldValueAndHandleErrors("vatCode", vatCode?.id);
      if (!touched.invoicePrice)
        void setFieldValueAndHandleErrors(
          "invoicePrice",
          transformMoneyFromAPIToMoney(invoicePrice?.price ?? { amount: "0", currency: "EUR" }),
        );
    },
    [values.existsOnInvoice, touched.revenueGroup, touched.vatCode, touched.invoicePrice, setFieldValueAndHandleErrors],
  );

  const setProjectServiceInvoiceMethod = useCallback(
    ({ invoiceMethod }: BuiltProjectService) => {
      if (isEditingService) return;
      if (touched.invoiceMethod) return;

      const method = subscriptionsEnabled || invoiceMethod !== InvoiceMethod.subscription ? invoiceMethod : undefined;

      void setFieldValueAndHandleErrors("invoiceMethod", method);
    },
    [touched.invoiceMethod, isEditingService, subscriptionsEnabled, setFieldValueAndHandleErrors],
  );

  const setValuesFromProjectService = useCallback(
    (buildProjectService: BuiltProjectService) => {
      setProjectServiceValues(buildProjectService);
      setProjectServiceHourAndCostValues(buildProjectService);
      setProjectServiceInvoiceValues(buildProjectService);
      setProjectServiceInvoiceMethod(buildProjectService);
    },
    [
      setProjectServiceValues,
      setProjectServiceHourAndCostValues,
      setProjectServiceInvoiceValues,
      setProjectServiceInvoiceMethod,
    ],
  );

  useBuildProjectService({
    defaultServiceValue: values.defaultService,
    defaultServiceTouched: touched.defaultService ?? false,
    projectId,
    isEditingService,
    setValuesFromProjectService,
  });

  return {
    values,
    costTypeHandlers: {
      addCostType,
      removeCostType,
      setCanRegisterCosts,
      setLabelForCostType,
      setQuantityForCostType,
      setPurchasePriceForCostType,
      setMarginForCostType,
      setSellingPriceForCostType,
      toggleIsInvoiceableForCostType,
    },
    hourTypeHandlers: {
      setAmountForHourType,
      setCanRegisterHours,
      setHourlyRateForHourType,
      toggleIsInvoiceableForHourType,
      addNewHourTypeEntry,
      removeNewHourTypeEntry,
      addHourType,
      removeHourTypes,
      setHourTypesTotal,
      setIsPlannable,
    },
    errors: errors as ProjectServiceFormErrors,
    touched,
    setDefaultService,
    setInvoiceMethod,
    setInvoiceTogetherWith,
    setCanInvoiceTogetherWith,
    handleSubmit,
    setSubscriptionCycle,
    setTimeframeStartDate,
    setTimeframeEndDate,
    setDescription,
    setExplanation,
    setRevenueGroup,
    setVatCode,
    setHasRegistrationTimeframe,
    setRegistrationTimeframeStartDate,
    setRegistrationTimeframeEndDate,
    setInvoiceableFrom,
    setInvoiceInInstallments,
    setInvoicePrice,
    setInvoiceQuantity,
    setHourlyRateForEmployee,
    isSubmitting,
  };
};
