import { SupportedFlags, useFeatureFlag } from "@simplicate/api-client";
import { BackButtonBehavior } from "@simplicate/top-bar";

export const FeatureFlagDependentBackButtonBehavior =
  (featureFlag: SupportedFlags, ifEnabled: URL | string, ifDisabled: URL | string): BackButtonBehavior =>
  () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks -- BackButtonBehaviors are hooks
    const { enabled, isFetching } = useFeatureFlag(featureFlag);

    if (isFetching) {
      return {
        target: undefined,
      };
    }

    return {
      target: enabled ? ifEnabled : ifDisabled,
    };
  };
