import { UseCubeQueryResult } from "@cubejs-client/react";
import { createContext, useContext, useMemo } from "react";
import { FilterPreset } from "../../data";
import { CubeDimension, Filter, RecordShape } from "../../types";
import { DashboardState, ReducerAction } from "./Dashboard.reducer";

export type DashboardContextType = {
  dashboardId: string;
  state: DashboardState;
  dispatch: (action: ReducerAction) => void;
  queryResult: UseCubeQueryResult<unknown, unknown>;
  onRecordSelect: (record: RecordShape) => void;
};

export const DashboardContext = createContext<DashboardContextType | null>(null);

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);

  if (context === null) {
    throw new Error("Can only use `useDashboardContext` inside `DashboardContextProvider`");
  }

  const { dispatch, state, queryResult, onRecordSelect } = context;

  const actions = useMemo(
    () => ({
      applyFilterForDimensions: (
        config: { labelDimension: CubeDimension; valueDimension: CubeDimension },
        values: { label: string; value: string }[],
      ) => dispatch({ type: "APPLY_FILTER_FOR_DIMENSION", config, values }),
      orderBy: (key: string) => dispatch({ type: "ORDER_BY", key }),
      removeFilter: (filter: Filter) => dispatch({ type: "REMOVE_FILTER", filter }),
      resetState: () => dispatch({ type: "RESET" }),
      setDateRange: (dateRange: { start: Date; end: Date }) => dispatch({ type: "SET_DATE_RANGE", dateRange }),
      setSearch: (search: string) => dispatch({ type: "SET_SEARCH", search }),
      toggleColumn: (id: string) => dispatch({ type: "TOGGLE_COLUMN", id }),
      updateColumns: (columns: { column: string; order: number; visible: boolean }[]) => {
        dispatch({ type: "UPDATE_COLUMNS", columns });
      },
      setView: (view: FilterPreset) => dispatch({ type: "SET_VIEW", view }),
    }),
    [dispatch],
  );

  return { state, actions, queryResult, onRecordSelect } as const;
};
