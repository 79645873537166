import { CurrencyFormat, NumberFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { Button, Table, Icon } from "@simplicate/ui";
import classnames from "classnames";
import { useState } from "react";
import { Big } from "../../../../../data";
import { HourTypeSelect } from "../HourTypeSelect";
import styles from "./HourTypeGridFooter.module.scss";
import type { HourTypeInForm, MoneyInForm } from "../../../types";

type HourTypeGridFooterProps = {
  onConfirmNewEntry: (entry: HourTypeInForm) => void;
  onCancelNewEntry: () => void;
  totalAmount: number;
  specifiedTotal?: MoneyInForm;
  disabled: boolean;
  value?: HourTypeInForm[];
  onChangeSpecifiedTotal: (value: Big | undefined) => void;
  showTariffColumn?: boolean;
};

export const HourTypeGridFooter = ({
  onConfirmNewEntry,
  onCancelNewEntry,
  totalAmount,
  specifiedTotal,
  disabled,
  value,
  onChangeSpecifiedTotal,
  showTariffColumn = true,
}: HourTypeGridFooterProps) => {
  const { t } = useTranslation("project_services");
  const [isAddingHourType, setIsAddingHourType] = useState(false);

  return (
    <>
      {isAddingHourType && (
        <Table.Footer variant="secondary">
          <HourTypeSelect
            hourTypesInForm={value}
            onConfirmNewEntry={(entry) => {
              setIsAddingHourType(false);
              onConfirmNewEntry(entry);
            }}
            onCancelNewEntry={() => {
              setIsAddingHourType(false);
              onCancelNewEntry();
            }}
            disabled={disabled}
          />
        </Table.Footer>
      )}
      <Table.Footer
        testId="hour-type-grid-footer"
        className={classnames(styles.footer, showTariffColumn && styles.hasTariffs)}
      >
        <div>
          <Button
            testId="add-new-hour-type"
            variant="secondary"
            onClick={() => setIsAddingHourType(true)}
            disabled={isAddingHourType || disabled}
          >
            <Icon icon="plus" />
            {t("add_hour_type_button")}
          </Button>
        </div>
        <span>{t("hour_type_budget_total")}</span>
        <NumberFormat
          title={`${totalAmount}`}
          className={classnames(styles.alignRight, styles.totalBudget, styles.fontNormal)}
          value={totalAmount}
        />
        <span className={styles.fontNormal}>{t("hour", { count: totalAmount })}</span>
        <span className={styles.alignRight}>
          <CurrencyFormat
            testId="specified-total"
            value={specifiedTotal?.amount?.toString()}
            displayType="input"
            disabled={disabled}
            size="small"
            textAlign="right"
            onValueChange={
              /* istanbul ignore next -- Component is mocked as <span/> making input test cases impossible */
              ({ value }, { source }) => {
                if (source.valueOf() === "prop") {
                  return;
                }
                if (value.length === 0) {
                  return onChangeSpecifiedTotal(undefined);
                }

                return onChangeSpecifiedTotal(Big(value));
              }
            }
            onBlur={() => {
              if (specifiedTotal?.amount === undefined) {
                onChangeSpecifiedTotal(Big(0));
              }
            }}
          />
        </span>
      </Table.Footer>
    </>
  );
};
