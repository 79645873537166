import classNames from "classnames";
import React from "react";
import { CanRegisterToggle } from "../fields/CanRegisterToggle";
import { UseProjectServiceFormReturnType } from "../hooks/useProjectServiceForm";
import styles from "./CanRegisterToggleHeader.module.scss";

export type CanRegisterHeaderProps = {
  isDefaultServiceUndefined: boolean;
  canRegister: boolean;
  setCanRegister:
    | UseProjectServiceFormReturnType["costTypeHandlers"]["setCanRegisterCosts"]
    | UseProjectServiceFormReturnType["hourTypeHandlers"]["setCanRegisterHours"];
  isCanRegisterLocked: boolean;
  errors?: React.ReactNode;
  labelText: string;
  tooltipText?: string;
  testId?: string;
};

export const CanRegisterToggleHeader: React.FC<CanRegisterHeaderProps> = ({
  isDefaultServiceUndefined,
  canRegister,
  setCanRegister,
  isCanRegisterLocked,
  labelText,
  tooltipText,
  testId,
  errors,
}) => {
  return (
    <div className={styles.gridTitleContainer}>
      <CanRegisterToggle
        label={
          <h3 className={classNames(styles.sectionTitle, isDefaultServiceUndefined && styles.disabled)}>{labelText}</h3>
        }
        testId={testId}
        value={canRegister ?? false}
        onChange={setCanRegister}
        disabled={isDefaultServiceUndefined || isCanRegisterLocked}
        tooltip={isCanRegisterLocked ? tooltipText : undefined}
      />
      {errors}
    </div>
  );
};
