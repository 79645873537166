import { HEADER_DATA_TAG, simplicateApiV2 } from "../components";
import { createTagTypes } from "../tags";
import { Response } from "./types";

export type Employee = {
  id: string;
  function: string;
  name: string;
  work_mobile?: string;
  work_email?: string;
  work_phone?: string;
  employment_status?: string;
  avatar?: {
    initials: string;
    color: string;
    url_small?: string;
    url_large?: string;
  };
};

const tags = createTagTypes({ tagPrefix: "Employee", tags: ["entity"] });

const endpoints = simplicateApiV2.enhanceEndpoints({ addTagTypes: Object.values(tags) }).injectEndpoints({
  endpoints: (builder) => ({
    getEmployee: builder.query<Response<Employee>, string>({
      query: (id) => ({
        url: `/hrm/employee/${id}`,
      }),
      providesTags: [tags.entity, HEADER_DATA_TAG],
    }),
  }),
});

export const { useGetEmployeeQuery } = endpoints;
