import {
  DropOptions,
  getBackendOptions,
  MultiBackend,
  NodeModel,
  NodeRender,
  Tree,
  TreeMethods,
  TreeProps,
} from "@minoru/react-dnd-treeview";
import { useCallback, useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { Placeholder, ServiceGroup, ServiceRow } from "./components";
import styles from "./GroupedServicesManager.module.scss";
import {
  GroupedServicesNodeModel,
  ServiceData,
  ServiceGroupData,
  ServiceGroupNodeModel,
  ServiceGroupType,
  ServiceNodeModel,
} from "./GroupedServicesManager.types";

export const isServiceGroup = (node?: GroupedServicesNodeModel): node is ServiceGroupNodeModel => !!node?.droppable;

export const canDrag = (node?: GroupedServicesNodeModel) =>
  (node !== undefined && !isServiceGroup(node)) ||
  (isServiceGroup(node) && node?.data?.groupType !== ServiceGroupType.UNGROUPED);

export const canDrop = (
  _tree: GroupedServicesNodeModel[],
  { dragSource, dropTarget, dropTargetId }: Omit<DropOptions<ServiceData | ServiceGroupData>, "monitor">,
) => {
  if (!dragSource) return;
  // Do not allow service groups to be dropped into other nodes.
  if (isServiceGroup(dragSource) && typeof dropTarget !== "undefined") {
    return false;
  }
  // Do not allow services to be dropped outside other nodes.
  if (!isServiceGroup(dragSource) && typeof dropTarget === "undefined") {
    return false;
  }
  // Allow moving within the same parent, required in combination with `sort={false}`.
  if (dragSource.parent === dropTargetId) {
    return true;
  }
  // @TODO Do not allow droppable nodes (containers) to be dropped above the ungrouped-container. This is currently not
  //   possible because the canDrop callback is not called with the destinationIndex of relativeIndex information. See
  //   also https://github.com/minop1205/react-dnd-treeview/issues/196.
};

type GroupedServicesManagerProps = {
  treeData: GroupedServicesNodeModel[];
  rootId: TreeProps["rootId"];
  onChangeTree?: (newTree: GroupedServicesNodeModel[]) => void;
};

export const GroupedServicesManager = ({ treeData, rootId, onChangeTree }: GroupedServicesManagerProps) => {
  const treeRef = useRef<TreeMethods>(null);
  const [dropTargetId, setDropTargetId] = useState<number | string | undefined>();
  const [draggedElementId, setDraggedElementId] = useState<number | string | undefined>();

  const renderNode: NodeRender<ServiceData | ServiceGroupData> = useCallback(
    (node, { hasChild, isDropTarget, handleRef }) =>
      isServiceGroup(node) ? (
        <ServiceGroup
          node={node}
          hasChild={hasChild}
          isDropTarget={isDropTarget}
          dropTargetId={dropTargetId}
          draggedElementId={draggedElementId}
          ref={handleRef as unknown as React.RefObject<HTMLDivElement>}
        />
      ) : (
        <ServiceRow node={node as ServiceNodeModel} ref={handleRef as unknown as React.RefObject<HTMLDivElement>} />
      ),
    [dropTargetId, draggedElementId],
  );

  useEffect(() => treeRef?.current?.openAll(), [treeData]);

  /* c8 ignore start -- Not worth testing since it merely links a callback to a useState setter. */
  /* istanbul ignore next -- Not worth testing since it merely links a callback to a useState setter. */
  const handleDrop = (newTree: GroupedServicesNodeModel[], options: DropOptions) => {
    setDropTargetId(options?.dropTarget?.id ?? undefined);
    setDraggedElementId(options?.dragSource?.id ?? undefined);
    onChangeTree?.(newTree);
  };
  /* c8 ignore end */

  return (
    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
      <Tree
        ref={treeRef}
        tree={treeData}
        rootId={rootId}
        initialOpen={true}
        render={renderNode}
        onDrop={handleDrop}
        classes={{
          root: styles.treeRoot,
          container: styles.container,
          draggingSource: styles.draggingSource,
          placeholder: styles.placeholderContainer,
          listItem: (node) => {
            if (isServiceGroup(node)) {
              return node.data?.groupType === ServiceGroupType.UNGROUPED
                ? `${styles.serviceGroup} ${styles.ungroupedContainer}`
                : styles.serviceGroup!;
            } else {
              return (node as NodeModel<ServiceData>).data?.isGrouped
                ? styles.serviceRow!
                : `${styles.serviceRow} ${styles.ungroupedRow}`;
            }
          },
        }}
        sort={false}
        insertDroppableFirst={false}
        canDrop={canDrop}
        canDrag={canDrag}
        dropTargetOffset={10}
        placeholderRender={/* istanbul ignore next */ () => <Placeholder />}
      />
    </DndProvider>
  );
};
