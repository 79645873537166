import { useTranslation } from "@simplicate/translations";
import { showToast } from "@simplicate/ui";
import { format } from "date-fns";
import { useFormik } from "formik";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Planning, UpdatePlanningBody, useUpdatePlanningMutation } from "../../../data";
import { buildValidationSchema } from "./salesPlanningFormValidation";

type UsePlanningSalesFormProps = {
  saleId?: string;
  planning: Partial<Planning>;
  afterSubmitTarget: string;
};

export const useSalesPlanningForm = ({ saleId, planning, afterSubmitTarget }: UsePlanningSalesFormProps) => {
  /* istanbul ignore next -- RTK is mocked in tests */
  const [updatePlanning, { isSuccess, isError }] = useUpdatePlanningMutation({
    selectFromResult: ({ isSuccess, isError }) => ({ isSuccess, isError }),
  });
  const validationSchema = buildValidationSchema();
  const { t } = useTranslation("sales_planning");
  const navigate = useNavigate();

  useEffect(() => {
    if (!isError) return;

    showToast({ message: t("error_save"), type: "error" });
  }, [isError, t]);

  useEffect(() => {
    if (!isSuccess) return;

    navigate(afterSubmitTarget);
  }, [isSuccess, navigate, afterSubmitTarget]);

  const { values, errors, touched, setFieldValue, setFieldTouched, handleSubmit, setErrors, isSubmitting } = useFormik<
    Partial<Planning>
  >({
    initialValues: planning,
    onSubmit: (values: Partial<Planning>) => {
      if (!(values && saleId)) {
        return;
      }

      const updateBody: UpdatePlanningBody = {
        saleId,
        ...(values as Planning), // Assume validation took place
      };

      return updatePlanning(updateBody);
    },
    validationSchema,
    enableReinitialize: true,
  });

  const setFieldValueAndHandleErrors = useCallback(
    async <K extends keyof Planning>(fieldName: K, fieldValue: Planning[K]) => {
      await setFieldTouched(fieldName);

      const errors = await setFieldValue(fieldName, fieldValue);

      if (errors) {
        setErrors(errors);
      }
    },
    [setErrors, setFieldTouched, setFieldValue],
  );

  const onUseInResourcePlannerChange = useCallback(
    async (fieldValue: "no" | "yes"): Promise<void> => {
      await setFieldValueAndHandleErrors("useInResourcePlanner", fieldValue === "yes");
    },
    [setFieldValueAndHandleErrors],
  );

  const onExpectedStartDateChange = useCallback(
    async (fieldValue: Date | undefined): Promise<void> => {
      const startDate = fieldValue ? format(fieldValue, "yyyy-MM-dd") : null;

      await setFieldValueAndHandleErrors("expectedStartDate", startDate);
    },
    [setFieldValueAndHandleErrors],
  );

  const onExpectedEndDateChange = useCallback(
    async (fieldValue: Date | undefined): Promise<void> => {
      const endDate = fieldValue ? format(fieldValue, "yyyy-MM-dd") : null;

      await setFieldValueAndHandleErrors("expectedEndDate", endDate);
    },
    [setFieldValueAndHandleErrors],
  );

  return {
    values,
    errors,
    touched,
    onUseInResourcePlannerChange,
    onExpectedStartDateChange,
    onExpectedEndDateChange,
    handleSubmit,
    isSubmitting,
  };
};
