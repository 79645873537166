import { InvoiceMethod } from "@simplicate/api-client";
import { format } from "date-fns";
import {
  BaseProjectServiceBody,
  EditProjectServiceBody,
  HourTypeConfigurationInBody,
  ProjectServiceBody,
  transformMoneyToMoneyFromAPI,
  Big,
  Money,
} from "../../data";
import { transformToCreateServiceCostType } from "./transformCostTypes";
import { transformToCreateServiceHourType } from "./transformHourTypes";
import type {
  ProjectServiceForm,
  ValidFixedPriceProjectServiceForm,
  ValidProjectServiceForm,
  ValidSubscriptionProjectServiceForm,
} from "./types";

const transformHourTypeValuesToHourTypeConfiguration = ({
  hourTypes,
  hourTypesSpecifiedTotal,
  invoiceMethod,
  employeeHourlyRates,
}: ValidProjectServiceForm): HourTypeConfigurationInBody => {
  return {
    hourTypes: hourTypes?.map((hourType) => transformToCreateServiceHourType(hourType, invoiceMethod)) ?? [],
    employeeHourlyRates: transformEmployeeHourlyRates(employeeHourlyRates),
    specifiedTotal: transformMoneyToMoneyFromAPI(hourTypesSpecifiedTotal ?? { currency: "EUR", amount: Big(0) }),
  };
};

const transformTimeframe = ({ startDate, endDate }: { startDate?: Date; endDate?: Date }) => {
  if (!startDate && !endDate) {
    return null;
  }

  return {
    startDate: startDate ? format(startDate, "yyyy-MM-dd") : null,
    endDate: endDate ? format(endDate, "yyyy-MM-dd") : null,
  };
};

const transformInvoicePrice = (invoiceQuantity: number | undefined, invoicePrice: Money) => ({
  quantity: invoiceQuantity ?? 1,
  price: transformMoneyToMoneyFromAPI(invoicePrice),
  totalPrice: transformMoneyToMoneyFromAPI({
    amount: invoicePrice.amount.mul(1),
    currency: invoicePrice.currency,
  }),
});

const transformFixedPriceInvoice = (
  base: BaseProjectServiceBody,
  values: ValidFixedPriceProjectServiceForm,
): ProjectServiceBody => ({
  ...base,
  invoiceMethod: InvoiceMethod.fixed_price,
  invoiceInInstallments: values.invoiceInInstallments,
  invoiceableFrom: values.invoiceableFrom ? format(values.invoiceableFrom, "yyyy-MM-dd") : null,
  invoicePrice: transformInvoicePrice(values.invoiceQuantity, values.invoicePrice),
});

const transformSubscriptionInvoice = (
  base: BaseProjectServiceBody,
  values: ValidSubscriptionProjectServiceForm,
): ProjectServiceBody => ({
  ...base,
  invoiceMethod: InvoiceMethod.subscription,
  subscriptionCycle: values.subscriptionCycle,
  invoicePrice: transformInvoicePrice(values.invoiceQuantity, values.invoicePrice),
  registrationTimeframe: values.hasRegistrationTimeframe ? transformTimeframe(values.registrationTimeframe) : undefined,
  invoiceTogetherWith: values.invoiceTogetherWith,
});

const transformTimeAndExpensesInvoice = (base: BaseProjectServiceBody): ProjectServiceBody => ({
  ...base,
  invoiceMethod: InvoiceMethod.time_and_expenses,
});

const transformEmployeeHourlyRates = (employeeHourlyRates: ProjectServiceForm["employeeHourlyRates"]) => {
  if (!employeeHourlyRates) {
    return [];
  }

  return employeeHourlyRates.map(({ employeeId, hourlyRate }) => ({
    employeeId,
    hourlyRate: transformMoneyToMoneyFromAPI(hourlyRate),
  }));
};

const transformFormToProjectServiceBody = (
  values: ValidProjectServiceForm,
  hasResourcePlanner: boolean,
): ProjectServiceBody => {
  const base: BaseProjectServiceBody = {
    defaultServiceId: values.defaultService,
    timeFrame: transformTimeframe(values.timeframe),
    description: values.description,
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- explanation can be an empty string
    explanation: values.explanation || null,
    revenueGroupId: values.revenueGroup,
    vatCodeId: values.vatCode,
    hoursRegistrationConfiguration: values.canRegisterHours
      ? transformHourTypeValuesToHourTypeConfiguration(values)
      : undefined,
    isPlannable: hasResourcePlanner ? values.isPlannable ?? false : undefined,
    costsRegistrationConfiguration: values.canRegisterCosts
      ? {
          costTypes:
            values.costTypes?.map((costType) => transformToCreateServiceCostType(costType, values.invoiceMethod)) ?? [],
        }
      : undefined,
  };

  if (values.invoiceMethod === InvoiceMethod.fixed_price) {
    return transformFixedPriceInvoice(base, values);
  }

  if (values.invoiceMethod === InvoiceMethod.subscription) {
    return transformSubscriptionInvoice(base, values);
  }

  return transformTimeAndExpensesInvoice(base);
};

export const transformFormToCreateProjectServiceBody = (
  values: ValidProjectServiceForm,
  projectId: string,
  hasResourcePlanner: boolean,
) => ({
  ...transformFormToProjectServiceBody(values, hasResourcePlanner),
  projectId,
});

export const transformFormToEditProjectServiceBody = (
  values: ValidProjectServiceForm,
  serviceId: string,
  hasResourcePlanner: boolean,
): EditProjectServiceBody => ({
  ...transformFormToProjectServiceBody(values, hasResourcePlanner),
  id: serviceId,
});
