import { useTranslation } from "@simplicate/translations";
import { ActionDropdown, Icon } from "@simplicate/ui";
import { lightColorIconError } from "@simplicate-software/design-tokens";
import { memo } from "react";

type ActionDropdownProps = {
  id: string;
  isInvoiceable: boolean | undefined;
  hasRegistrations: boolean;
  showIsInvoiceableColumn: boolean;
  onIsInvoiceableToggled: (id: string) => void;
  onRemoveHourTypeEntries: (ids: string[]) => void;
  disabled: boolean;
};

export const HourTypeActionDropdown = memo(function HourTypeActionDropdown({
  id,
  isInvoiceable,
  hasRegistrations,
  showIsInvoiceableColumn,
  onIsInvoiceableToggled,
  onRemoveHourTypeEntries,
  disabled,
}: ActionDropdownProps) {
  const { t } = useTranslation("project_services");

  return (
    <ActionDropdown
      testId="hour-type-actions"
      button={
        <ActionDropdown.Button variant="subtle" disabled={disabled} testId="hour-type-actions-button">
          <Icon icon="ellipsisVertical" />
        </ActionDropdown.Button>
      }
    >
      {showIsInvoiceableColumn && (
        <ActionDropdown.Action
          disabled={isInvoiceable && hasRegistrations}
          tooltip={isInvoiceable && hasRegistrations ? t("cannot_toggle_is_invoiceable_for_hour_type") : undefined}
          onClick={() => onIsInvoiceableToggled(id)}
        >
          <Icon icon="fileInvoice" />
          {isInvoiceable ? t("set_is_not_invoiceable") : t("set_is_invoiceable")}
        </ActionDropdown.Action>
      )}
      <ActionDropdown.Action
        tooltip={hasRegistrations ? t("cannot_remove_hour_type") : undefined}
        disabled={hasRegistrations}
        onClick={() => onRemoveHourTypeEntries([id])}
      >
        <Icon icon="trash" color={lightColorIconError} />
        {t("remove_from_grid")}
      </ActionDropdown.Action>
    </ActionDropdown>
  );
});
