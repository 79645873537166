import { PayloadAction, RootState, createSlice, Reducer, createSelector } from "@simplicate/state";
import { Filter } from "../../types";
import { FilterPreset } from "../endpoints";

type PersistedColumn = {
  column: string;
  order: number;
  visible: boolean;
};

type ColumnOrder = {
  column: string;
  direction: "asc" | "desc";
};
export type DashboardViewState = {
  view?: FilterPreset;
  columns?: PersistedColumn[];
  dashboardId: string;
  order?: ColumnOrder[];
  filters?: Filter[];
};

export type DashboardSlice = Record<string, DashboardViewState & { createdAt: number; updatedAt: number }>;

const slice = createSlice(
  {
    namespace: "insights",
    localStorageKey: "insights_dashboard_views",
  },
  {
    name: "Insights Dashboard State",
    initialState: {},
    reducerPath: "dashboard_views",
    reducers: {
      updateDashboard: (state: DashboardSlice, { payload }: PayloadAction<DashboardViewState>) => {
        const now = Date.now();

        state[payload.dashboardId] = {
          createdAt: now,
          ...state[payload.dashboardId],
          ...payload,
          dashboardId: payload.dashboardId,
          updatedAt: now,
        };
      },
    },
  },
);

export const getDashboardById = (dashboardId: string) =>
  createSelector(
    (state: RootState<{ insights: { dashboard_views: DashboardSlice } }>) => state.state.insights.dashboard_views,
    (dashboard_views) =>
      dashboard_views[dashboardId] ?? {
        dashboardId,
        createdAt: 0,
        updatedAt: 0,
        columns: [],
        filters: [],
        order: undefined,
        view: undefined,
      },
  );

export const { updateDashboard } = slice.actions;

export const _dashboardSliceReducer: Reducer<DashboardSlice> = slice.reducer;
