import { SubscriptionCycle } from "@simplicate/api-client";
import { useTranslation, type TFunction } from "@simplicate/translations";
import { Select, Tooltip } from "@simplicate/ui";
import { memo } from "react";
import { useGetSubscriptionCyclesQuery } from "../../../data";

type SubscriptionCycleSelectProps = {
  value?: SubscriptionCycle;
  touched?: boolean;
  error: string | undefined;
  onSelect: (method: SubscriptionCycle) => void;
  disabled: boolean;
  disabledTooltip?: string;
};

function translateSubscriptionCycle(cycle: SubscriptionCycle, t: TFunction<"project_services", undefined>): string {
  switch (cycle) {
    case "monthly":
      return t("monthly");
    case "quarterly":
      return t("quarterly");
    case "half_yearly":
      return t("half_yearly");
    case "yearly":
      return t("yearly");
  }
}

export const SubscriptionCycleSelect = memo(function SubscriptionCycleSelect({
  value,
  error,
  onSelect,
  disabled,
  touched = false,
  disabledTooltip,
}: SubscriptionCycleSelectProps) {
  const { t } = useTranslation("project_services");

  const { data: options } = useGetSubscriptionCyclesQuery();

  const compatibleOptions = options?.cycleOptions.map((option) => ({
    value: option,
    label: translateSubscriptionCycle(option, t),
  }));

  const select = (
    <Select
      testId="subscription_cycle_select"
      name="subscriptionCycle"
      label={t("subscription_cycle")}
      placeholder={t("subscription_cycle_placeholder")}
      emptyMessage={t("no_default_services")}
      value={value}
      options={compatibleOptions}
      onSelect={onSelect}
      invalid={touched && error !== undefined}
      disabled={disabled}
      filter={false}
    />
  );

  return (
    <>
      {!disabledTooltip && select}
      {disabled && disabledTooltip && (
        <Tooltip message={disabledTooltip} position="right">
          {select}
        </Tooltip>
      )}
    </>
  );
});
