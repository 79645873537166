import { SelectMulti } from "@simplicate/ui";
import { useCallback, useMemo } from "react";
import { useDataset } from "../../data";
import { CubeDimension, cubeDimensionToKey } from "../../types";

type ValueType = { label: string; value: string; key: string };

export type DimensionSelectProps = {
  labelDimension: CubeDimension;
  valueDimension: CubeDimension;
  placeholder?: string;
  value?: string[];
  onChange: (value: ValueType[]) => void;
};

export const DimensionValueSelect = ({
  labelDimension,
  valueDimension,
  placeholder,
  onChange,
  value,
}: DimensionSelectProps) => {
  const { resultSet } = useDataset({
    dimensions: [cubeDimensionToKey(labelDimension), cubeDimensionToKey(valueDimension)],
  });

  const options = useMemo(() => {
    if (!resultSet) return [];

    return resultSet
      .tablePivot()
      .map((record): ValueType | undefined => {
        const labelKey = cubeDimensionToKey(labelDimension);
        const valueKey = cubeDimensionToKey(valueDimension);

        if (record[valueKey] === undefined) {
          return undefined;
        }

        const label = record[labelKey];
        const value = record[valueKey];

        return {
          key: String(value),
          label: String(label),
          value: String(value),
        };
      })
      .filter((option): option is ValueType => option !== undefined);
  }, [labelDimension, resultSet, valueDimension]);

  const handleChange = useCallback(
    (newValue: string[]) => {
      const selectedOptions = options.filter((option) => newValue.includes(option.value));

      onChange(selectedOptions);
    },
    [onChange, options],
  );

  return (
    <SelectMulti
      value={value}
      placeholder={placeholder}
      options={options}
      onChange={handleChange}
      showSelectedCount
      width="default"
    />
  );
};
