import { useTranslation } from "@simplicate/translations";
import { DialogResult, Icon, Link } from "@simplicate/ui";
import { useCallback } from "react";
import { FilterPreset, useCreateFilterPresetMutation } from "../../data";
import { useDashboardContext } from "../Dashboard";

type EditNameActionProps = {
  view?: FilterPreset;
  askForName: (initialValue?: string, options?: { dialogTitle?: string }) => Promise<DialogResult<{ name: string }>>;
};

export const SaveAsAction = ({ askForName, view }: EditNameActionProps) => {
  const { t } = useTranslation("insights");
  const {
    state: { filters, columns, order },
    actions: { setView },
  } = useDashboardContext();

  const [createFilterPreset] = useCreateFilterPresetMutation();

  const handleClick = useCallback(() => {
    // istanbul ignore if -- this is a guard to keep TS happy.
    if (view === undefined) {
      return;
    }

    askForName(undefined, { dialogTitle: t("general.add_view") })
      .then((result) => {
        if (result.status === "submit" && result.data) {
          const newView = {
            ...view,
            id: undefined,
            name: result.data.name,
            filters,
            columns,
            order: order ?? [],
          };

          setView(newView);
          void createFilterPreset(newView);
        }
      })
      .catch(console.error);
  }, [askForName, columns, createFilterPreset, filters, order, setView, t, view]);

  return (
    <Link testId="edit-view-button" onClick={handleClick}>
      <Icon icon="floppyDisk" fixedWidth />
      {t("general.add_view")}
    </Link>
  );
};
