import { useTranslation } from "@simplicate/translations";
import { Portal, usePortalContext, showToast } from "@simplicate/ui";
import { useState, useLayoutEffect } from "react";
import { GroupType, ServiceGroup, useUpdateServiceGroupMutation } from "../../../../../data";
import { LoadingFallback } from "../LoadingFallback";
import { ServiceGroupModal } from "../ServiceGroupModal";

type EditServiceGroupModalProps = {
  serviceGroup?: ServiceGroup;
  onClose: () => void;
};

export const EditServiceGroupModal = ({ serviceGroup, onClose }: EditServiceGroupModalProps) => {
  const [updateServiceGroup, { isError, isSuccess, isLoading }] = useUpdateServiceGroupMutation();
  const { targetRefs } = usePortalContext();

  const { t } = useTranslation("grouped_services_manager");

  const [name, setName] = useState<string>(serviceGroup?.name ?? "");
  const [description, setDescription] = useState<string>(serviceGroup?.description ?? "");
  const [groupType, setGroupType] = useState<GroupType>(serviceGroup?.type ?? "all_included");

  const handleUpdateServiceGroup = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!serviceGroup) return;
    void updateServiceGroup({
      serviceGroupId: serviceGroup.id,
      name,
      description,
      type: groupType,
    });
  };

  useLayoutEffect(() => {
    if (isSuccess) {
      onClose();

      return;
    }
    if (isLoading) {
      return;
    }
    if (isError) {
      showToast({ message: t("error_messages.failed_to_edit_group"), type: "error" });
    }
  }, [isError, isSuccess, onClose, isLoading, t]);

  const serviceGroupId = serviceGroup?.id.toString();

  return (
    <>
      {isLoading && serviceGroupId ? (
        <Portal targetRef={targetRefs[serviceGroupId]}>
          <LoadingFallback type="group" text={t("loading_messages.edit_group")} />
        </Portal>
      ) : (
        <ServiceGroupModal
          isOpen={true}
          onClose={onClose}
          onSubmit={handleUpdateServiceGroup}
          isEditModal={true}
          name={name}
          description={description}
          onNameChange={(e) => setName(e.target.value)}
          onDescriptionChange={(e) => setDescription(e.target.value)}
          onGroupTypeChange={setGroupType}
          groupType={groupType}
        />
      )}
    </>
  );
};
