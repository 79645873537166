import { FeatureFlagSwitch } from "@simplicate/api-client";
import { SalesServicesPage, SalesPlanningPage } from "@simplicate/pages";
import { Navigate } from "react-router-dom";
import { IFrameEnvironment, LegacyWebComponent, QuoteEditor, SalesTabs } from "../../components";
import { QuoteSubHeader, SalesSubHeader } from "../../components/NavigationWrapper/SubHeaders";
import { RouteObject } from "../types";
import { followBackendRedirect } from "../utils";

export const salesRoutes: RouteObject<"acquisition">[] = [
  {
    path: "sales",
    handle: {
      theme: "sales",
      headerTitle: "module_sales",
      permissions: [
        {
          module_key: "acquisition",
          right_key: "view",
        },
      ],
    },
    children: [
      {
        index: true,
        element: <Navigate to="open" replace />,
      },
      {
        path: "open",
        element: <LegacyWebComponent />,
      },
      {
        path: "accepted",
        element: <LegacyWebComponent />,
      },
      {
        path: "rejected",
        element: <LegacyWebComponent />,
      },
      {
        path: "all",
        element: <LegacyWebComponent />,
      },
      {
        path: "reporting",
        handle: {
          permissions: [
            {
              module_key: "acquisition",
              right_key: "rapportage",
            },
          ],
        },
        children: [
          {
            index: true,
            element: <Navigate to="funnel" replace />,
          },
          {
            path: "funnel",
            element: <IFrameEnvironment src="/v1/reporting/sales" remountOnSearchChange={false} />,
            handle: {
              headerTitle: "sales_reporting_funnel",
            },
          },
        ],
      },
      {
        path: ":id",
        handle: {
          backNavigationPath: "/sales/open",
          subHeaderComponent: SalesSubHeader,
          tabsComponent: SalesTabs,
        },
        children: [
          {
            index: true,
            element: <Navigate to="overview" replace />,
          },
          {
            path: "overview",
            element: <LegacyWebComponent />,
          },
          {
            path: "general",
            element: <IFrameEnvironment src="/v1/acquisition/opportunity/view?id=:id&tab=general" />,
          },
          {
            path: "planning",
            element: (
              <FeatureFlagSwitch
                featureFlag="resource-planner-allow-planning-on-sales"
                ifEnabled={<SalesPlanningPage />}
                ifDisabled={<Navigate to="/404/" />}
              />
            ),
          },
          {
            path: "services",
            children: [
              {
                index: true,
                element: (
                  <FeatureFlagSwitch
                    featureFlag="sales-service-groups"
                    ifEnabled={<SalesServicesPage />}
                    ifDisabled={<IFrameEnvironment src="/v1/acquisition/opportunity/view?id=:id&tab=service" />}
                  />
                ),
              },
              {
                path: "new",
                element: <IFrameEnvironment src="/v1/acquisition/opportunity/editServicePage?id=:id&tab=service" />,
              },
            ],
          },
          {
            path: "service",
            children: [
              {
                path: ":serviceId",
                element: (
                  <IFrameEnvironment src="/v1/acquisition/opportunity/editServicePage?serviceId=:serviceId&tab=service" />
                ),
              },
            ],
          },
          {
            path: "quotes",
            children: [
              {
                index: true,
                element: <LegacyWebComponent />,
              },
              {
                path: ":qid",
                element: <QuoteEditor />,
                handle: { subHeaderComponent: QuoteSubHeader },
              },
            ],
          },
          {
            path: "tasks",
            element: <IFrameEnvironment src="/v1/acquisition/opportunity/view?id=:id&tab=tasks" />,
          },
          {
            path: "documents",
            element: <IFrameEnvironment src="/v1/acquisition/opportunity/view?id=:id&tab=dossier" />,
          },
        ],
      },
    ],
  },
  {
    path: "v1/acquisition/scored/createinvoice",
    loader: followBackendRedirect,
    element: null,
  },
];
