import { CurrencyFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { Table, buildColumnProps } from "@simplicate/ui";
import { size8 } from "@simplicate-software/design-tokens";
import { memo } from "react";
import { Big } from "../../../../data";
import { Employee } from "./Employee/Employee";
import type { EmployeeHourlyRateInForm } from "../../types";

type EmployeeHourlyRatesGridProps = {
  value: EmployeeHourlyRateInForm[];
  testId?: string;
  onHourlyRateChange: (employeeId: string, hourlyRate: Big) => void;
};

export const EmployeeHourlyRatesGrid = memo(function EmployeeHourlyRatesGrid({
  value,
  testId,
  onHourlyRateChange,
}: EmployeeHourlyRatesGridProps) {
  const { t } = useTranslation("project_services");

  return (
    <Table testId={testId} value={value} emptyMessage={t("hourly_rate_employee_empty")}>
      <Table.Column
        {...buildColumnProps({
          header: t("hourly_rate_employee_header"),
          field: "employeeId",
          body: ({ employeeId }: EmployeeHourlyRateInForm) => employeeId && <Employee id={employeeId} />,
          width: "dynamic",
          alignContent: "center",
        })}
      />

      <Table.Column
        {...buildColumnProps({
          header: t("hourly_rate_employee_hourly_rate_header"),
          field: "hourlyRate",
          align: "right",
          body: ({ hourlyRate, employeeId }: EmployeeHourlyRateInForm) => (
            <CurrencyFormat
              testId={testId && `${testId}-input`}
              displayType="input"
              size="small"
              textAlign="right"
              value={hourlyRate.amount?.toString()}
              onValueChange={
                /* istanbul ignore next -- Component is mocked as <span/> making input test cases impossible */
                ({ value }) => onHourlyRateChange(employeeId, Big(value || 0))
              }
            />
          ),
          width: size8,
        })}
      />
    </Table>
  );
});
