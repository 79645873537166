import { useTranslation } from "@simplicate/translations";
import { Checkbox, Tooltip } from "@simplicate/ui";
import { memo } from "react";
import styles from "./InvoiceInInstallmentsCheckbox.module.scss";

type InvoiceInInstallmentsCheckboxProps = {
  value: boolean | undefined;
  disabled: boolean;
  tooltip?: string;
  onChange: (value: boolean) => void;
};

export const InvoiceInInstallmentsCheckbox = memo(function InvoiceInInstallmentsCheckbox({
  value = false,
  disabled,
  tooltip,
  ...props
}: InvoiceInInstallmentsCheckboxProps) {
  const { t } = useTranslation("project_services");

  const checkbox = (
    <Checkbox
      name="invoiceInInstallments"
      testId="invoice-in-installments-checkbox"
      label={t("invoice_in_installments")}
      value={value}
      disabled={disabled}
      {...props}
    />
  );

  return (
    <div className={styles.invoiceInInstallmentsCheckboxContainer}>
      {tooltip ? (
        <Tooltip message={tooltip} position="right">
          {checkbox}
        </Tooltip>
      ) : (
        checkbox
      )}
    </div>
  );
});
