// istanbul ignore file -- This component will be covered by e2e tests
import { Query } from "@cubejs-client/core";
import { usePageTitle } from "@simplicate/state";
import { TFunction, useTranslation } from "@simplicate/translations";
import { Page } from "@simplicate/ui";
import { CubeProvider, Dashboard } from "../../components";
import { ViewMenu } from "../../components/ViewMenu";
import { Column } from "../../types";
import { FilterWidget, ViewActionsWidget, TableWidget } from "../../widgets";
import { DashboardReportPageTemplate } from "../DashboardReportPageTemplate";

const DASHBOARD_ID = "workflows-details-report";
const QUERY: Query = {
  dimensions: [
    "workflow_details.workflow_created_at",
    "workflow_details.workflow_type",
    "workflow_details.relation_name",
    "workflow_details.workflow_description",
    "workflow_details.workflow_step_name",
    "workflow_details.workflow_status",
    "workflow_details.workflow_step_owner",
    "workflow_details.employee_created_by_name",
    "workflow_details.project_name",
    "workflow_details.sales_name",
    "workflow_details.relation_accountmanager",
    "workflow_details.project_supervisor_name",
    "workflow_details.sales_responsible_person",
    "workflow_details.workflow_updated_at",
  ],
  measures: [
    "workflow_details.workflow_step_entered_date",
    "workflow_details.workflow_step_entered_days_since",
    "workflow_details.workflow_step_deadline_date",
    "workflow_details.workflow_step_deadline_days_until",
  ],
  order: {
    "workflow_details.workflow_created_at": "desc",
  },
};

const EMPLOYEE_CREATED_BY_NAME = ["workflow_details", "employee_created_by_name"] as const;
const MEASURE_STEP_DEADLINE_DATE = ["workflow_details", "workflow_step_deadline_date"] as const;
const MEASURE_STEP_DEADLINE_DAYS_UNTIL = ["workflow_details", "workflow_step_deadline_days_until"] as const;
const MEASURE_STEP_ENTERED_DATE = ["workflow_details", "workflow_step_entered_date"] as const;
const MEASURE_STEP_ENTERED_DAYS_SINCE = ["workflow_details", "workflow_step_entered_days_since"] as const;
const PROJECT_NAME = ["workflow_details", "project_name"] as const;
const PROJECT_SUPERVISOR_NAME = ["workflow_details", "project_supervisor_name"] as const;
const RELATION_ACCOUNTMANAGER = ["workflow_details", "relation_accountmanager"] as const;
const RELATION_NAME = ["workflow_details", "relation_name"] as const;
const SALES_NAME = ["workflow_details", "sales_name"] as const;
const SALES_RESPONSIBLE_PERSON = ["workflow_details", "sales_responsible_person"] as const;
const WORKFLOW_CREATED_AT = ["workflow_details", "workflow_created_at"] as const;
const WORKFLOW_DESCRIPTION = ["workflow_details", "workflow_description"] as const;
const WORKFLOW_STATUS = ["workflow_details", "workflow_status"] as const;
const WORKFLOW_STEP_NAME = ["workflow_details", "workflow_step_name"] as const;
const WORKFLOW_STEP_OWNER = ["workflow_details", "workflow_step_owner"] as const;
const WORKFLOW_TYPE = ["workflow_details", "workflow_type"] as const;
const WORKFLOW_UPDATED_AT = ["workflow_details", "workflow_updated_at"] as const;

const COLUMNS_CONFIG: Column[] = [
  {
    dimension: WORKFLOW_CREATED_AT,
    title: (t: TFunction<"insights">) => t("workflows.workflow_created_at_column_title"),
    sortable: true,
    width: "dynamic",
    align: "left",
  },
  {
    dimension: WORKFLOW_TYPE,
    title: (t: TFunction<"insights">) => t("workflows.workflow_type_column_title"),
    sortable: true,
    width: "dynamic",
    align: "left",
  },
  {
    dimension: RELATION_NAME,
    title: (t: TFunction<"insights">) => t("workflows.relation_name_column_title"),
    sortable: true,
    width: "dynamic",
    align: "left",
  },
  {
    dimension: WORKFLOW_DESCRIPTION,
    title: (t: TFunction<"insights">) => t("workflows.workflow_description_column_title"),
    sortable: true,
    width: "dynamic",
    align: "left",
  },
  {
    dimension: WORKFLOW_STEP_NAME,
    title: (t: TFunction<"insights">) => t("workflows.workflow_step_name_column_title"),
    sortable: true,
    width: "dynamic",
    align: "left",
  },
  {
    dimension: WORKFLOW_STATUS,
    title: (t: TFunction<"insights">) => t("workflows.workflow_status_column_title"),
    sortable: true,
    width: "dynamic",
    align: "left",
  },
  {
    dimension: WORKFLOW_STEP_OWNER,
    title: (t: TFunction<"insights">) => t("workflows.workflow_step_owner_column_title"),
    sortable: true,
    width: "dynamic",
    align: "left",
  },
  {
    dimension: EMPLOYEE_CREATED_BY_NAME,
    title: (t: TFunction<"insights">) => t("workflows.employee_created_by_name_column_title"),
    sortable: true,
    width: "dynamic",
    align: "left",
  },
  {
    dimension: PROJECT_NAME,
    title: (t: TFunction<"insights">) => t("workflows.project_name_column_title"),
    sortable: true,
    width: "dynamic",
    align: "left",
  },
  {
    dimension: SALES_NAME,
    title: (t: TFunction<"insights">) => t("workflows.sales_name_column_title"),
    sortable: true,
    width: "dynamic",
    align: "left",
  },
  {
    dimension: RELATION_ACCOUNTMANAGER,
    title: (t: TFunction<"insights">) => t("workflows.relation_accountmanager_column_title"),
    sortable: true,
    width: "dynamic",
    align: "left",
  },
  {
    dimension: PROJECT_SUPERVISOR_NAME,
    title: (t: TFunction<"insights">) => t("workflows.project_supervisor_name_column_title"),
    sortable: true,
    width: "dynamic",
    align: "left",
  },
  {
    dimension: SALES_RESPONSIBLE_PERSON,
    title: (t: TFunction<"insights">) => t("workflows.sales_responsible_person_column_title"),
    sortable: true,
    width: "dynamic",
    align: "left",
  },
  {
    dimension: WORKFLOW_UPDATED_AT,
    title: (t: TFunction<"insights">) => t("workflows.workflow_updated_at_column_title"),
    sortable: true,
    width: "dynamic",
    align: "left",
  },
  {
    dimension: MEASURE_STEP_DEADLINE_DATE,
    title: (t: TFunction<"insights">) => t("workflows.workflow_deadline_date"),
    sortable: true,
    width: "dynamic",
    align: "left",
  },
  {
    dimension: MEASURE_STEP_DEADLINE_DAYS_UNTIL,
    title: (t: TFunction<"insights">) => t("workflows.workflow_deadline_days_until"),
    sortable: true,
    width: "dynamic",
    align: "left",
  },
  {
    dimension: MEASURE_STEP_ENTERED_DATE,
    title: (t: TFunction<"insights">) => t("workflows.workflow_entered_date"),
    sortable: true,
    width: "dynamic",
    align: "left",
  },
  {
    dimension: MEASURE_STEP_ENTERED_DAYS_SINCE,
    title: (t: TFunction<"insights">) => t("workflows.workflow_entered_days_since"),
    sortable: true,
    width: "dynamic",
    align: "left",
  },
];

export const WorkflowsReportPageDetails = () => {
  const { t } = useTranslation("insights");

  usePageTitle(t("workflows.details_report_title"));

  return (
    <Page>
      <CubeProvider>
        <Dashboard dashboardId={DASHBOARD_ID} query={QUERY}>
          <DashboardReportPageTemplate>
            <DashboardReportPageTemplate.Header>
              <DashboardReportPageTemplate.Filters>
                <FilterWidget dimensions={[]} />
              </DashboardReportPageTemplate.Filters>
              <DashboardReportPageTemplate.Settings>
                <ViewMenu dashboardId={DASHBOARD_ID} />
                <ViewActionsWidget columns={COLUMNS_CONFIG} />
              </DashboardReportPageTemplate.Settings>
            </DashboardReportPageTemplate.Header>
            <DashboardReportPageTemplate.Table>
              <TableWidget title={t("workflows.time_entry_table_title")} columns={COLUMNS_CONFIG} />
            </DashboardReportPageTemplate.Table>
          </DashboardReportPageTemplate>
        </Dashboard>
      </CubeProvider>
    </Page>
  );
};
