import { InvoiceMethod, skipToken } from "@simplicate/api-client";
import { CurrencyFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { Alert, Icon, Tooltip, Page, Footer, Button, useDialogRef, Spinner, Checkbox } from "@simplicate/ui";
import { format } from "date-fns";
import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  type ListSubscriptionsThatCanBeInvoicedTogetherBody,
  useGetProjectServiceQuery,
  useListSubscriptionsThatCanBeInvoicedTogetherQuery,
  Big,
} from "../../data";
import {
  ApplyChangedDefaultServiceValuesDialog,
  type DefaultServiceDialogForm,
} from "./ApplyChangedDefaultServiceValuesDialog";
import { DefaultServiceSelect } from "./fields/DefaultServiceSelect";
import { DescriptionTextInput } from "./fields/DescriptionTextInput";
import { ExplanationTextArea } from "./fields/ExplanationTextArea";
import { InvoiceableFromDatepicker } from "./fields/InvoiceableFromDatepicker";
import { InvoicedUntil } from "./fields/InvoicedUntil";
import { InvoiceInInstallmentsCheckbox } from "./fields/InvoiceInInstallmentsCheckbox";
import { InvoiceMethodSelect } from "./fields/InvoiceMethodSelect";
import { InvoicePriceInput } from "./fields/InvoicePriceInput";
import { InvoiceQuantityInput } from "./fields/InvoiceQuantityInput";
import { InvoiceTogetherWith } from "./fields/InvoiceTogetherWith";
import { RevenueGroupSelect } from "./fields/RevenueGroupSelect";
import { SubscriptionCycleSelect } from "./fields/SubscriptionCycleSelect";
import { TimeframeEndDatePicker } from "./fields/TimeframeEndDatePicker";
import { TimeframeStartDatePicker } from "./fields/TimeframeStartDatePicker";
import { VATCodeSelect } from "./fields/VATCodeSelect";
import { EmployeeHourlyRatesGrid } from "./grids/EmployeeHourlyRatesGrid";
import { useProjectServiceForm } from "./hooks/useProjectServiceForm";
import { scrollToError } from "./hooks/useProjectServiceFormHelpers";
import { useServiceTotal } from "./hooks/useServiceTotal";
import { ProjectServiceHeader } from "./ProjectServiceHeader";
import styles from "./ProjectServicePage.module.scss";
import { ProjectServicePageTemplate } from "./ProjectServicePage.template";
import { CostTypeSection } from "./ProjectServicePageCostSection";
import { HourTypeSection } from "./ProjectServicePageHourSection";
import { ProjectServicePageSkeleton } from "./ProjectServicePageSkeleton";
import { ProjectServiceTotalInfo } from "./ProjectServiceTotalInfo";
import { transformProjectServiceToForm } from "./transformProjectServiceToForm";
import { ProjectServiceForm } from "./types";

type ProjectServicePageParams = {
  id: string;
  serviceId?: string;
};

const useProjectService = (serviceId?: string) => {
  const { data, isLoading } = useGetProjectServiceQuery(serviceId ?? skipToken);

  const existingProjectServiceValues = data ? transformProjectServiceToForm(data) : undefined;

  return { existingProjectServiceValues, existingProjectService: data, isLoading };
};

type useSubscriptionsThatCanBeInvoicedTogetherValues = Pick<ProjectServiceForm, "subscriptionCycle" | "timeframe"> & {
  currentServiceId?: string;
};

const useSubscriptionsThatCanBeInvoicedTogetherValidator = (
  fields: Partial<ListSubscriptionsThatCanBeInvoicedTogetherBody>,
) => {
  const { t } = useTranslation("project_services");
  const { startDate, projectId, subscriptionCycle } = fields;
  const missingFields: string[] = [];

  if (!startDate) missingFields.push(t("invoice_together_with_missing_start_date"));
  if (!projectId) missingFields.push(t("invoice_together_with_missing_project_id"));
  if (!subscriptionCycle) missingFields.push(t("invoice_together_with_missing_subscription_cycle"));

  return { isValid: missingFields.length === 0, missingFields };
};

const useSubscriptionsThatCanBeInvoicedTogether = ({
  timeframe: { startDate },
  subscriptionCycle,
}: useSubscriptionsThatCanBeInvoicedTogetherValues) => {
  const { id: projectId, serviceId } = useParams();

  const fields = {
    projectId,
    currentServiceId: serviceId,
    startDate: startDate ? format(startDate, "yyyy-MM-dd") : undefined,
    subscriptionCycle,
  };

  const { isValid, missingFields } = useSubscriptionsThatCanBeInvoicedTogetherValidator(fields);

  const { data: services } = useListSubscriptionsThatCanBeInvoicedTogetherQuery(
    isValid ? (fields as ListSubscriptionsThatCanBeInvoicedTogetherBody) : skipToken,
  );

  return { services: services ?? [], missingFields, isValid };
};

// eslint-disable-next-line complexity, sonarjs/cognitive-complexity -- This component is large,
export const ProjectServicePage = () => {
  const { t } = useTranslation("project_services");
  const navigate = useNavigate();

  const params = useParams<ProjectServicePageParams>();

  const { id: projectId, serviceId } = params;

  const dialogRef = useDialogRef<DefaultServiceDialogForm>();
  const formRef = useRef<HTMLFormElement>(null);

  const {
    existingProjectServiceValues,
    existingProjectService,
    isLoading: serviceLoading,
  } = useProjectService(serviceId);

  const {
    values,
    costTypeHandlers,
    hourTypeHandlers,
    errors,
    touched,
    setDefaultService,
    setInvoiceMethod,
    setInvoiceTogetherWith,
    handleSubmit: handleFormikSubmit,
    setSubscriptionCycle,
    setTimeframeStartDate,
    setTimeframeEndDate,
    setDescription,
    setExplanation,
    setRevenueGroup,
    setVatCode,
    setHasRegistrationTimeframe,
    setRegistrationTimeframeStartDate,
    setRegistrationTimeframeEndDate,
    setInvoiceableFrom,
    setInvoiceInInstallments,
    setInvoicePrice,
    setInvoiceQuantity,
    setHourlyRateForEmployee,
    isSubmitting,
  } = useProjectServiceForm({
    initialValues: existingProjectServiceValues,
    projectId,
    dialogRef,
    serviceId,
    afterSubmitTarget: `/projects/${projectId}/services`,
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isSubmitting) handleFormikSubmit?.(e);
    if (Object.keys(errors).length > 0) scrollToError(errors, formRef);
  };

  const {
    services: subscriptionsThatCanBeInvoicedTogether,
    missingFields: missingSubscriptionsThatCanBeInvoicedTogetherFields,
    isValid,
  } = useSubscriptionsThatCanBeInvoicedTogether({
    currentServiceId: serviceId,
    timeframe: values.timeframe,
    subscriptionCycle: values.subscriptionCycle,
  });

  useEffect(() => {
    const ids = subscriptionsThatCanBeInvoicedTogether.map(({ id }) => id);

    if (values.invoiceTogetherWith && !ids.includes(values.invoiceTogetherWith)) {
      setInvoiceTogetherWith(undefined);
    }
  }, [subscriptionsThatCanBeInvoicedTogether, values.invoiceTogetherWith, setInvoiceTogetherWith]);

  const canHaveInvoiceableFromValue = !values.invoiceInInstallments;
  const isDefaultServiceUndefined = values.defaultService === undefined;

  const isEditingService = serviceId !== undefined;

  const invoiceMethodIsSubscription = values.invoiceMethod === InvoiceMethod.subscription;
  const invoiceMethodIsFixedPrice = values.invoiceMethod === InvoiceMethod.fixed_price;

  const isTimeframeStartDateLocked =
    isEditingService && invoiceMethodIsSubscription && !!existingProjectService?.timeFrame?.startDate;

  const budgetTotal = useServiceTotal({
    hourTypesSpecifiedTotalAmount: values.hourTypesSpecifiedTotal?.amount ?? Big(0),
    costTypes: values.costTypes ?? [],
  });

  const invoicePrice = invoiceMethodIsFixedPrice ? values.invoicePrice?.amount : budgetTotal;

  if (serviceLoading) {
    return <ProjectServicePageSkeleton testId="project-service-page-skeleton" />;
  }

  return (
    <Page
      footer={
        <Footer>
          <Button type="submit" form="projectservice-form" testId="submit-button">
            {t("buttons.save", { ns: "general" })}
          </Button>
          <Button onClick={() => navigate(-1)} variant="subtle" testId="cancel-button">
            {t("buttons.cancel", { ns: "general" })}
          </Button>
          {isSubmitting && (
            <div className={styles.isSubmittingContainer}>
              <Spinner testId="project-service-page-is-submitting-spinner" />
              <span className={styles.normalText}>{t("service_is_being_submitted")}</span>
            </div>
          )}
        </Footer>
      }
    >
      <ApplyChangedDefaultServiceValuesDialog ref={dialogRef} />

      <form onSubmit={handleSubmit} ref={formRef} id="projectservice-form" data-testid="project-service-page">
        <ProjectServicePageTemplate>
          <ProjectServicePageTemplate.Header>
            <Alert type="info">
              <span>{t("pilot_notice")}</span>
            </Alert>

            <ProjectServiceHeader
              title={isEditingService ? values.description ?? "" : t("create_project_service_page_title")}
              invoiceMethod={isEditingService ? values.invoiceMethod : undefined}
              price={isEditingService ? invoicePrice : undefined}
            />
          </ProjectServicePageTemplate.Header>
          <ProjectServicePageTemplate.Section>
            <ProjectServicePageTemplate.PropertiesBlock>
              <ProjectServicePageTemplate.Title>
                <h4 className={styles.subTitle}>{t("basic_properties")}</h4>
              </ProjectServicePageTemplate.Title>

              <ProjectServicePageTemplate.PropertiesBlockColumn>
                <DefaultServiceSelect
                  value={values.defaultService}
                  error={errors.defaultService}
                  touched={touched.defaultService}
                  onSelect={setDefaultService}
                  disabled={values.existsOnInvoice}
                  tooltip={values.existsOnInvoice ? t("default_service_locked_invoices_tooltip") : undefined}
                />

                <div className={styles.invoiceMethodWrapper}>
                  <div className={styles.invoiceMethod}>
                    <InvoiceMethodSelect
                      value={values.invoiceMethod}
                      error={errors.invoiceMethod}
                      touched={touched.invoiceMethod}
                      onSelect={setInvoiceMethod}
                      disabled={isEditingService || isDefaultServiceUndefined}
                      disabledTooltip={isEditingService ? t("invoice_method_cannot_be_changed") : undefined}
                    />
                  </div>

                  {invoiceMethodIsSubscription && (
                    <div className={styles.subscriptionCycle}>
                      <SubscriptionCycleSelect
                        value={values.subscriptionCycle}
                        error={errors.subscriptionCycle}
                        touched={touched.subscriptionCycle}
                        onSelect={setSubscriptionCycle}
                        disabled={isEditingService}
                        disabledTooltip={isEditingService ? t("subscription_cycle_cannot_be_changed") : undefined}
                      />
                    </div>
                  )}
                </div>

                <div className={styles.timeframeLayout}>
                  <div className={styles.timeframeSection}>
                    <h4 className={styles.subTitle}>{t("timeframe")}</h4>

                    <Tooltip message={t("optional")} position="right">
                      <Icon icon="infoCircle" className={styles.infoIcon} />
                    </Tooltip>
                  </div>

                  <div className={styles.timeframeFields}>
                    <div className={styles.timeframeFieldContainer}>
                      <TimeframeStartDatePicker
                        value={values.timeframe.startDate}
                        onChange={setTimeframeStartDate}
                        label={invoiceMethodIsSubscription ? t("start_subscription") : t("start_date")}
                        error={errors.timeframe?.startDate}
                        touched={touched.timeframe?.startDate}
                        disabled={isDefaultServiceUndefined || isTimeframeStartDateLocked}
                        disabledTooltip={
                          isTimeframeStartDateLocked ? t("subscription_timeframe_start_cannot_be_changed") : undefined
                        }
                      />
                    </div>
                    <div className={styles.timeframeFieldContainer}>
                      <TimeframeEndDatePicker
                        value={values.timeframe.endDate}
                        label={invoiceMethodIsSubscription ? t("end_subscription") : t("end_date")}
                        minValue={values.timeframe.startDate}
                        onChange={setTimeframeEndDate}
                        error={errors.timeframe?.endDate}
                        touched={touched.timeframe?.endDate}
                        disabled={
                          isDefaultServiceUndefined || (invoiceMethodIsSubscription && !values.timeframe.startDate)
                        }
                      />
                    </div>
                  </div>
                </div>
              </ProjectServicePageTemplate.PropertiesBlockColumn>

              <ProjectServicePageTemplate.PropertiesBlockColumn>
                <DescriptionTextInput
                  value={values.description}
                  error={errors.description}
                  touched={touched.description}
                  onChange={setDescription}
                  disabled={isDefaultServiceUndefined}
                />

                <ExplanationTextArea
                  value={values.explanation}
                  error={errors.explanation}
                  touched={touched.explanation}
                  onChange={setExplanation}
                  disabled={isDefaultServiceUndefined}
                />
              </ProjectServicePageTemplate.PropertiesBlockColumn>
            </ProjectServicePageTemplate.PropertiesBlock>

            <ProjectServicePageTemplate.OptionsBlock>
              <ProjectServicePageTemplate.Title>
                <h4 className={styles.subTitle}>{t("advanced_properties")}</h4>
              </ProjectServicePageTemplate.Title>

              <ProjectServicePageTemplate.OptionsBlockColumn>
                <div className={styles.column}>
                  <RevenueGroupSelect
                    value={values.revenueGroup}
                    error={errors.revenueGroup}
                    touched={touched.revenueGroup}
                    onSelect={setRevenueGroup}
                    disabled={isDefaultServiceUndefined || values.existsOnInvoice}
                    tooltip={values.existsOnInvoice ? t("revenue_group_locked_invoices_tooltip") : undefined}
                  />

                  <VATCodeSelect
                    value={values.vatCode}
                    error={errors.vatCode}
                    touched={touched.vatCode}
                    onSelect={setVatCode}
                    disabled={isDefaultServiceUndefined || values.existsOnInvoice}
                    tooltip={values.existsOnInvoice ? t("vat_code_locked_invoices_tooltip") : undefined}
                  />

                  {values.invoiceMethod === InvoiceMethod.subscription && (
                    <>
                      <Checkbox
                        name="hasRegistrationTimeframe"
                        value={values?.hasRegistrationTimeframe ?? false}
                        onChange={setHasRegistrationTimeframe}
                        label={t("alternate_registration_timeframe")}
                      />
                      {values.hasRegistrationTimeframe && (
                        <div className={styles.registrationTimeframe}>
                          <TimeframeStartDatePicker
                            onChange={setRegistrationTimeframeStartDate}
                            value={values.registrationTimeframe?.startDate}
                            label={invoiceMethodIsSubscription ? t("start_subscription") : t("start_date")}
                            disabled={false}
                            error={errors.registrationTimeframe}
                            touched={touched.registrationTimeframe}
                          />
                          <TimeframeEndDatePicker
                            onChange={setRegistrationTimeframeEndDate}
                            value={values.registrationTimeframe?.endDate}
                            label={invoiceMethodIsSubscription ? t("end_subscription") : t("end_date")}
                            disabled={false}
                            minValue={values.registrationTimeframe?.startDate}
                            error={errors.registrationTimeframe}
                            touched={touched.registrationTimeframe}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </ProjectServicePageTemplate.OptionsBlockColumn>
            </ProjectServicePageTemplate.OptionsBlock>
          </ProjectServicePageTemplate.Section>
          <ProjectServicePageTemplate.Section>
            <ProjectServicePageTemplate.Content>
              <HourTypeSection
                hourTypeHandlers={hourTypeHandlers}
                values={values}
                touched={touched}
                isDefaultServiceUndefined={isDefaultServiceUndefined}
                errors={errors}
              />
            </ProjectServicePageTemplate.Content>

            {values.employeeHourlyRates && (
              <ProjectServicePageTemplate.Content>
                <EmployeeHourlyRatesGrid
                  testId="employee-hourly-rates-grid"
                  value={values.employeeHourlyRates}
                  onHourlyRateChange={setHourlyRateForEmployee}
                />
              </ProjectServicePageTemplate.Content>
            )}

            <ProjectServicePageTemplate.Content>
              <CostTypeSection
                costTypeHandlers={costTypeHandlers}
                values={values}
                touched={touched}
                isDefaultServiceUndefined={isDefaultServiceUndefined}
                errors={errors}
              />
            </ProjectServicePageTemplate.Content>
          </ProjectServicePageTemplate.Section>
          <ProjectServicePageTemplate.Footer>
            <ProjectServicePageTemplate.TotalBlock>
              <div className={styles.totalBlock}>
                <div className={styles.serviceTotalContainer}>
                  <span className={styles.normalText}>{t("service_total")}</span>
                  <CurrencyFormat className={styles.normalText} displayType="text" value={budgetTotal.toString()} />
                </div>
              </div>
            </ProjectServicePageTemplate.TotalBlock>

            {(invoiceMethodIsFixedPrice || invoiceMethodIsSubscription) && (
              <ProjectServicePageTemplate.Content>
                <div className={styles.invoicingLayout}>
                  <h4 className={styles.subTitle}>{t("invoicing")}</h4>

                  <div className={styles.invoicePrice}>
                    <InvoiceQuantityInput
                      value={values.invoiceQuantity}
                      onChange={setInvoiceQuantity}
                      disabled={values.existsOnInvoice}
                      tooltip={values.existsOnInvoice ? t("invoice_quantity_locked_invoices_tooltip") : undefined}
                    />
                    <InvoicePriceInput
                      value={values.invoicePrice}
                      onChange={setInvoicePrice}
                      disabled={values.existsOnInvoice}
                      tooltip={values.existsOnInvoice ? t("invoice_price_locked_invoices_tooltip") : undefined}
                    />

                    {invoiceMethodIsFixedPrice && !values.existsOnInvoice && (
                      <ProjectServiceTotalInfo
                        calculatedTotal={budgetTotal}
                        invoicePrice={values.invoicePrice}
                        setInvoicePrice={setInvoicePrice}
                      />
                    )}
                  </div>

                  <div className={styles.invoicingFields}>
                    {invoiceMethodIsSubscription && (
                      <>
                        {existingProjectService?.invoiceMethod === InvoiceMethod.subscription &&
                          existingProjectService.invoicedUntil && (
                            <InvoicedUntil
                              label={t("invoiced_until")}
                              value={new Date(existingProjectService.invoicedUntil)}
                            />
                          )}

                        <InvoiceTogetherWith
                          services={subscriptionsThatCanBeInvoicedTogether}
                          missingFields={missingSubscriptionsThatCanBeInvoicedTogetherFields}
                          disabled={!isValid}
                          onChange={setInvoiceTogetherWith}
                          value={values.invoiceTogetherWith}
                        />
                      </>
                    )}
                    {invoiceMethodIsFixedPrice && (
                      <>
                        <InvoiceableFromDatepicker
                          value={canHaveInvoiceableFromValue ? values.invoiceableFrom : undefined}
                          onChange={setInvoiceableFrom}
                          error={errors.invoiceableFrom}
                          touched={touched.invoiceableFrom}
                          disabled={!!values.invoiceInInstallments || !!values.existsOnInvoice}
                          tooltip={values.existsOnInvoice ? t("invoiceable_from_locked_invoices_tooltip") : undefined}
                        />

                        <InvoiceInInstallmentsCheckbox
                          value={values.invoiceInInstallments}
                          onChange={setInvoiceInInstallments}
                          disabled={!!values.hasInstallmentPlan || !!values.existsOnInvoice}
                          tooltip={values.hasInstallmentPlan ? t("invoice_in_installments_locked") : undefined}
                        />
                      </>
                    )}
                  </div>
                </div>
              </ProjectServicePageTemplate.Content>
            )}
          </ProjectServicePageTemplate.Footer>
        </ProjectServicePageTemplate>
      </form>
    </Page>
  );
};
