import { useTranslation } from "@simplicate/translations";
import { Button, Dialog, Icon, Link, useDialogRef } from "@simplicate/ui";
import { lightColorIconError } from "@simplicate-software/design-tokens";
import { useCallback } from "react";
import { useDeleteFilterPresetMutation } from "../../data";
import styles from "./DeleteViewAction.module.scss";

type DeleteViewActionProps = {
  viewId?: number;
};

export const DeleteViewAction = ({ viewId }: DeleteViewActionProps) => {
  const { t } = useTranslation("insights");
  const ref = useDialogRef();
  const [deleteViewPreset] = useDeleteFilterPresetMutation();

  const handleClick = useCallback(() => {
    // eslint-disable-next-line sonarjs/void-use -- we don't need to await this.
    void ref.current?.open().then(
      /* istanbul ignore next -- requires mocking refs */ (result) => {
        if (result.status === "submit") {
          void deleteViewPreset(viewId!);
        }
      },
    );
  }, [deleteViewPreset, ref, viewId]);

  return (
    <>
      <Link testId="delete-view-button" onClick={handleClick} variant="danger" disabled={viewId === undefined}>
        <Icon icon="trash" color={lightColorIconError} fixedWidth />
        {t("general.delete_view")}
      </Link>
      <Dialog ref={ref}>
        <form className={styles.dialogContent}>
          <h3 className={styles.header}>
            <Icon icon="trash" fixedWidth />
            {t("general.delete_are_you_sure")}
          </h3>
          <p className={styles.body}>{t("general.delete_view_confirmation")}</p>
          <div className={styles.footer}>
            <Button type="submit">{t("general.button_delete")}</Button>
            <Button type="reset" variant="secondary">
              {t("general.button_cancel")}
            </Button>
          </div>
        </form>
      </Dialog>
    </>
  );
};
