import {
  size10,
  size11,
  size12,
  size13,
  size4,
  size5,
  size6,
  size7,
  size8,
  size9,
} from "@simplicate-software/design-tokens";
import classNames from "classnames";
import { ColumnBodyOptions, ColumnProps as PrimeReactColumnProps } from "primereact/column";
import { ReactNode } from "react";
// eslint-disable-next-line css-modules/no-unused-class -- classes are used
import styles from "./Column.module.scss";

const DEFAULT_COLUMN_WIDTH = size10;

export type ColumnProps<TData extends object> = Omit<
  PrimeReactColumnProps,
  "body" | "bodyClassName" | "className" | "footerClassName" | "headerClassName" | "selectionMode" | "style"
> & {
  width?:
    | typeof size4
    | typeof size5
    | typeof size6
    | typeof size7
    | typeof size8
    | typeof size9
    | typeof size10
    | typeof size11
    | typeof size12
    | typeof size13
    | "dynamic";
  isNumeric?: boolean;
  body?: ReactNode | ((data: TData, options: ColumnBodyOptions) => React.ReactNode);
  alignContent?: "baseline" | "center" | "end" | "start";
};

function buildStyles<TData extends object>({
  isNumeric = false,
  width = DEFAULT_COLUMN_WIDTH,
  alignContent = "baseline",
}: ColumnProps<TData>): Pick<
  PrimeReactColumnProps,
  "bodyClassName" | "bodyStyle" | "footerClassName" | "headerClassName" | "style"
> {
  return {
    headerClassName: classNames(isNumeric && styles.numericContent),
    bodyClassName: classNames(isNumeric && styles.numericContent),
    footerClassName: classNames(isNumeric && styles.numericContent),
    bodyStyle: {
      alignContent,
    },
    style:
      width === "dynamic"
        ? {
            minWidth: DEFAULT_COLUMN_WIDTH,
            width: "auto",
          }
        : {
            minWidth: width,
            width: width,
          },
  };
}

export function buildColumnProps<TData extends object = object>(
  inputProps?: ColumnProps<TData>,
): PrimeReactColumnProps {
  const { body, ...props } = inputProps ?? {};

  return {
    body,
    ...props,
    ...buildStyles(props),
  };
}
