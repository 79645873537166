import { useTranslation } from "@simplicate/translations";
import { Icon, Link } from "@simplicate/ui";
import { useCallback } from "react";
import { FilterPreset, useUpdateFilterPresetMutation } from "../../data";
import { useDashboardContext } from "../Dashboard";

type ViewActionProps = {
  view?: FilterPreset;
};

export const SaveViewAction = ({ view }: ViewActionProps) => {
  const { t } = useTranslation("insights");
  const {
    state: { filters, columns, order },
  } = useDashboardContext();

  const [updateFilterPreset] = useUpdateFilterPresetMutation();

  const handleClick = useCallback(() => {
    if (view === undefined) return;

    const newView = {
      ...view,
      filters,
      columns,
      order: /* istanbul ignore next */ order ?? [],
    };

    void updateFilterPreset(newView);
  }, [columns, updateFilterPreset, filters, order, view]);

  return (
    <Link testId="save-view-button" onClick={handleClick}>
      <Icon icon="floppyDisk" fixedWidth />
      {t("general.save_view")}
    </Link>
  );
};
