import { InvoiceMethod } from "@simplicate/api-client";
import { FixedPriceService, ProjectService, SubscriptionService, transformMoneyFromAPIToMoney } from "../../data";
import { transformCostTypeInServiceToCostTypeInForm } from "./transformCostTypes";
import { transformToHourTypeInForm, transformEmployeeHourlyRatesInForm } from "./transformHourTypes";
import { ProjectServiceForm } from "./types";

const transformFixedPriceInvoice = (
  base: ProjectServiceForm,
  projectService: FixedPriceService,
): ProjectServiceForm => ({
  ...base,
  invoiceMethod: InvoiceMethod.fixed_price,
  invoiceableFrom: projectService.invoiceableFrom ? new Date(projectService.invoiceableFrom) : undefined,
  invoiceInInstallments: projectService.invoiceInInstallments,
  invoicePrice: projectService.invoicePrice
    ? transformMoneyFromAPIToMoney(projectService.invoicePrice.price)
    : undefined,
  hasInstallmentPlan: projectService.hasInstallmentPlan,
});

const transformSubscriptionInvoice = (
  base: ProjectServiceForm,
  projectService: SubscriptionService,
): ProjectServiceForm => {
  const { registrationTimeframe } = projectService;

  return {
    ...base,
    invoiceMethod: InvoiceMethod.subscription,
    subscriptionCycle: projectService.subscriptionCycle,
    invoicePrice: transformMoneyFromAPIToMoney(projectService.invoicePrice.price),
    registrationTimeframe: {
      startDate: registrationTimeframe?.startDate ? new Date(registrationTimeframe.startDate) : undefined,
      endDate: registrationTimeframe?.endDate ? new Date(registrationTimeframe.endDate) : undefined,
    },
    invoiceTogetherWith: projectService.invoiceTogetherWith,
  };
};

const transformTimeAndExpensesInvoice = (base: ProjectServiceForm): ProjectServiceForm => ({
  ...base,
  invoiceMethod: InvoiceMethod.time_and_expenses,
});

export const transformProjectServiceToForm = (projectService: ProjectService): ProjectServiceForm => {
  const {
    timeFrame: timeframe,
    hoursRegistrationConfiguration: { hourTypeTotals, canRegisterHours, hourTypes, employeeHourlyRates },
    costsRegistrationConfiguration: { canRegisterCosts, costTypes },
  } = projectService;

  const base: ProjectServiceForm = {
    defaultService: projectService.defaultServiceId,
    description: projectService.description,
    explanation: projectService.explanation,
    timeframe: {
      startDate: timeframe?.startDate ? new Date(timeframe.startDate) : undefined,
      endDate: timeframe?.endDate ? new Date(timeframe.endDate) : undefined,
    },
    revenueGroup: projectService.revenueGroupId,
    vatCode: projectService.vatCodeId,
    canRegisterHours,
    canRegisterCosts,
    isPlannable: projectService.isPlannable,
    hourTypes: hourTypes.map((hourType) => transformToHourTypeInForm(hourType)),
    hourTypesSpecifiedTotal: hourTypeTotals ? transformMoneyFromAPIToMoney(hourTypeTotals.specifiedTotal) : undefined,
    costTypes: costTypes.map((costType) => transformCostTypeInServiceToCostTypeInForm(costType)),
    existsOnInvoice: projectService.existsOnInvoice,
    hasAssignments: projectService.hasAssignments,
    employeeHourlyRates: transformEmployeeHourlyRatesInForm(employeeHourlyRates),
  };

  if (projectService.invoiceMethod === InvoiceMethod.fixed_price) {
    return transformFixedPriceInvoice(base, projectService);
  }

  if (projectService.invoiceMethod === InvoiceMethod.subscription) {
    return transformSubscriptionInvoice(base, projectService);
  }

  return transformTimeAndExpensesInvoice(base);
};
