import { useFeatureFlag, useLicenseGuard } from "@simplicate/api-client";
import { useEffect } from "react";
import { Outlet, To, useNavigate } from "react-router-dom";

const CRM_PATH = "/crm";
const LANDING_PAGE_PATH = "/dashboard/overview";

type RedirectToFirstAccessiblePageProps = {
  to?: To;
};

export const RedirectToFirstAccessiblePage = ({ to }: RedirectToFirstAccessiblePageProps) => {
  const { enabled: hasDashboardModuleLicense, isLoading } = useLicenseGuard("dashboard_module");
  const { enabled: hasDashboardLandingPage, isFetching: isLoadingFeatureFlag } =
    useFeatureFlag("dashboard-landing-page");
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading || isLoadingFeatureFlag) {
      return;
    }

    if (!hasDashboardModuleLicense) {
      navigate(CRM_PATH, { replace: true });
    } else if (hasDashboardLandingPage) {
      navigate(LANDING_PAGE_PATH, { replace: true });
    } else if (to) {
      navigate(to, { replace: true });
    }
  }, [hasDashboardLandingPage, hasDashboardModuleLicense, isLoading, isLoadingFeatureFlag, navigate, to]);

  return <Outlet />;
};
