import { useTranslation } from "@simplicate/translations";
import { Clickable, Dropdown, Icon } from "@simplicate/ui";
import classNames from "classnames";
import { useEffect, useState, useCallback, useRef } from "react";
import { FilterPreset } from "../../data";
import { useFilterPresets } from "../../utils";
import { useDashboardContext } from "../Dashboard";
import { SaveViewDialog, SaveViewDialogHandle } from "../SaveViewDialog";
import { DeleteViewAction } from "./DeleteViewAction";
import { EditNameAction } from "./EditNameAction";
import { SaveAsAction } from "./SaveAsAction";
import { SaveViewAction } from "./SaveViewAction";
import styles from "./ViewMenu.module.scss";

type ViewMenuProps = {
  dashboardId: string;
};

export const ViewMenu = ({ dashboardId }: ViewMenuProps) => {
  const { t } = useTranslation("insights");
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<SaveViewDialogHandle>(null);
  const views = useFilterPresets(dashboardId);

  const {
    actions: { setView },
    state: { view: currentView },
  } = useDashboardContext();

  const [selectedView, setSelectedView] = useState<FilterPreset | undefined>(currentView);

  const handleViewChange = (viewId: number) => {
    const selectedView = views.find((view) => view.id === viewId);

    // istanbul ignore else -- The selected view should always exist
    if (selectedView) {
      setSelectedView(selectedView);
      setView(selectedView);
    }

    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedView(currentView);
  }, [currentView]);

  const toggleIsOpen = useCallback(() => setIsOpen((prev) => !prev), []);
  const askForName = useCallback(
    /* istanbul ignore next -- this just passes on arguments if the ref exists */ (
      name?: string,
      options?: { dialogTitle?: string },
    ) => ref.current?.open(name, options) ?? Promise.resolve({ status: "cancel" }),
    [],
  );

  return (
    <>
      <div className={styles.wrapper}>
        <Clickable onClick={toggleIsOpen} testId="view-menu-button" className={styles.viewMenuButton}>
          <Icon icon="tableLayout" />
          <div className={styles.text}>{selectedView?.name}</div>
          <Icon icon="angleDown" />
        </Clickable>

        {isOpen && (
          <div className={styles.dropdownContainer}>
            <Dropdown
              isOpen={isOpen}
              width="default"
              height="auto"
              variant="clean"
              onClickOutside={/* istanbul ignore next */ () => setIsOpen(false)}
            >
              <div className={styles.dropdownContent}>
                <div className={styles.viewContent}>
                  <span className={styles.listHeader}>{t("general.view_menu_placeholder")}</span>
                  {views.map(({ id, name }) => (
                    <Clickable
                      key={id}
                      testId={`view-menu-${id}`}
                      onClick={() => id && handleViewChange(id)}
                      title={name}
                      className={styles.listItem}
                    >
                      <span className={classNames(styles.icon, selectedView?.id === id && styles.selected)}>
                        <Icon icon="check" testId="check-icon" fixedWidth color={undefined} />
                      </span>
                      <span>{name}</span>
                    </Clickable>
                  ))}
                </div>
                <div className={styles.actionContainer}>
                  <SaveViewAction view={selectedView} />
                  <SaveAsAction askForName={askForName} view={selectedView} />
                  <EditNameAction askForName={askForName} view={selectedView} />
                </div>
                <div className={styles.actionContainer}>
                  <DeleteViewAction viewId={selectedView?.id} />
                </div>
              </div>
            </Dropdown>
          </div>
        )}
      </div>

      <SaveViewDialog ref={ref} />
    </>
  );
};
