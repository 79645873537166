import {
  simplicateApiV2,
  simplicateApiV3,
  createTagTypes,
  type InvoiceMethod,
  type SubscriptionCycle,
} from "@simplicate/api-client";
import { CostType, HourTypeFromApi, InvoicePrice } from "../types";
import type { MoneyFromAPI } from "../utils";

export type GroupType = "all_included" | "multiple_optional" | "single_optional" | "single_required";

export type ServiceHourTypeTotal = {
  calculatedTotal: MoneyFromAPI;
  specifiedTotal: MoneyFromAPI;
  hoursBudget: number;
};

export type ServiceHourType = {
  defaultHourType: HourTypeFromApi;
  hourlyRate: MoneyFromAPI;
  amountOfHours: number;
  isInvoiceable: boolean | null;
  total: MoneyFromAPI;
};

export type SellingMargin = {
  percentagePoints: number;
};

export type ServiceHourTypeConfiguration = {
  hourTypeTotals: ServiceHourTypeTotal;
  hourTypes: ServiceHourType[];
};

export type ServiceCostTypeTotal = {
  total: MoneyFromAPI;
  budget: MoneyFromAPI;
};

export type ServiceCostType = {
  id: string;
  defaultCostType: CostType;
  description: string;
  purchasePrice: MoneyFromAPI;
  sellingPrice: MoneyFromAPI;
  quantity: number;
  isInvoiceable: boolean | null;
  margin: SellingMargin;
  total: MoneyFromAPI;
};

export type Service = {
  id: string;
  defaultServiceId: string;
  description: string;
  explanation: string | null;
  saleQuantity: number;
  sellingPrice: MoneyFromAPI;
  invoicePrice?: InvoicePrice;
  invoiceMethod: InvoiceMethod;
  subscriptionCycle?: SubscriptionCycle;
  revenueGroupId: string;
  vatCodeId: string;
  hourTypeConfiguration?: ServiceHourTypeConfiguration;
  costTypes: ServiceCostType[];
  costTypeTotals?: ServiceCostTypeTotal;
  totalPrice: MoneyFromAPI;
};

type VatDivision = {
  code: string;
  description: string;
  vatAmount: MoneyFromAPI;
};

export type Totals = {
  totalExcluding: MoneyFromAPI;
  totalVat: MoneyFromAPI;
  totalIncluding: MoneyFromAPI;
  vatDivisions: VatDivision[];
};

export type ServiceGroup = {
  id: string;
  name: string;
  description: string;
  type: GroupType;
  services: Service[];
  totals: Totals;
};

type UngroupedServices = {
  services: Service[];
  totals: Totals;
};

export type Sales = {
  id: string;
  name: string;
  groupedServices: ServiceGroup[];
  ungroupedServices: UngroupedServices;
  planning: Planning;
};

export type CreateServiceGroupBody = {
  saleId: string;
  name: string;
  description: string;
  type: GroupType;
};

export type UpdateServiceGroupBody = {
  serviceGroupId: string;
  name?: string;
  description?: string;
  type?: GroupType;
};

export type DeleteServiceGroupBody = {
  saleId: string;
  serviceGroupId: string;
  shouldDeleteServices: boolean;
};

export type DuplicateServiceBody = {
  serviceId: string;
};

export type ChangeServiceTreeBody = {
  saleId: string;
  serviceGroupStructure: {
    structureForGroups: {
      groupId: string;
      serviceIds: string[];
    }[];
    ungroupedServiceIds: string[];
  };
};

export type Planning = {
  useInResourcePlanner: boolean;
  expectedStartDate: string | null;
  expectedEndDate: string | null;
};

export type UpdatePlanningBody = {
  saleId: string;
  useInResourcePlanner: boolean;
  expectedStartDate: string | null;
  expectedEndDate: string | null;
};

const tags = createTagTypes({
  tagPrefix: "Sales",
  tags: ["list", "sale"],
});

const endpointsV3 = simplicateApiV3.enhanceEndpoints({ addTagTypes: Object.values(tags) }).injectEndpoints({
  endpoints: (builder) => ({
    getSales: builder.query<Sales, string>({
      query: (id) => ({
        url: `/sale.get/${id}`,
      }),
      providesTags: (result) => (result ? [{ type: tags.sale, id: result.id }, tags.list] : [tags.list]),
    }),
    createServiceGroup: builder.mutation<Sales, CreateServiceGroupBody>({
      query: (body) => ({
        url: `/saleService.group.create`,
        body: body,
        method: "POST",
      }),
      invalidatesTags: (result) => (result ? [{ type: tags.sale, id: result.id }, tags.list] : [tags.list]),
    }),
    deleteServiceGroup: builder.mutation<Sales, DeleteServiceGroupBody>({
      query: (body) => ({
        url: `/saleService.group.delete`,
        body: body,
        method: "POST",
      }),
      invalidatesTags: (result) => (result ? [{ type: tags.sale, id: result.id }, tags.list] : [tags.list]),
    }),
    updateServiceGroup: builder.mutation<Sales, UpdateServiceGroupBody>({
      query: (body) => ({
        url: `/saleService.group.update`,
        body: body,
        method: "POST",
      }),
      invalidatesTags: (result) => (result ? [{ type: tags.sale, id: result.id }, tags.list] : [tags.list]),
    }),
    duplicateService: builder.mutation<Sales, DuplicateServiceBody>({
      query: (body) => ({
        url: `/saleService.duplicate`,
        body: body,
        method: "POST",
      }),
      invalidatesTags: (result) => (result ? [{ type: tags.sale, id: result.id }, tags.list] : [tags.list]),
    }),
    changeServiceTree: builder.mutation<Sales, ChangeServiceTreeBody>({
      query: (body) => ({
        url: `/saleService.group.updateServiceTree`,
        body: body,
        method: "POST",
      }),
      invalidatesTags: (result) => (result ? [{ type: tags.sale, id: result.id }, tags.list] : [tags.list]),
    }),

    autoGroupServices: builder.mutation<Sales, string>({
      query: (saleId) => ({
        url: `/saleService.group.autoGroupByInvoiceMethod`,
        body: { saleId },
        method: "POST",
      }),
      invalidatesTags: (result, _error, saleId) =>
        result ? [{ type: tags.sale, id: saleId }, tags.list] : [tags.list],
    }),

    updatePlanning: builder.mutation<Planning, UpdatePlanningBody>({
      query: (body) => ({
        url: `/sale.updatePlanning`,
        body,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetSalesQuery,
  useCreateServiceGroupMutation,
  useDeleteServiceGroupMutation,
  useUpdateServiceGroupMutation,
  useDuplicateServiceMutation,
  useChangeServiceTreeMutation,
  useAutoGroupServicesMutation,
  useUpdatePlanningMutation,
} = endpointsV3;

const endpointsV2 = simplicateApiV2.enhanceEndpoints({ addTagTypes: Object.values(tags) }).injectEndpoints({
  endpoints: (builder) => ({
    deleteSalesService: builder.mutation<null, string>({
      query: (id) => ({
        url: `sales/service/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, _error, id) => (result ? [{ type: tags.sale, id: id }, tags.list] : [tags.list]),
      onQueryStarted: (arg, api) => {
        void api.queryFulfilled.then(() => {
          // @ts-expect-error -- See https://github.com/reduxjs/redux-toolkit/issues/2484.
          api.dispatch(simplicateApiV3.util.invalidateTags([{ type: tags.sale, id: arg }, tags.list]));
        });
      },
    }),
  }),
});

export const { useDeleteSalesServiceMutation } = endpointsV2;
