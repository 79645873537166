import { simplicateApiV3, createTagTypes } from "@simplicate/api-client";
import { Filter, cubeDimensionToKey } from "../../../types";
import { convertStringToCubeDimension } from "../../hooks";

type ApiFilter = Omit<Filter, "labelDimension" | "valueDimension"> & {
  valueColumn: string;
  labelColumn: string;
};

type PersistedColumn = {
  column: string;
  order: number;
  visible: boolean;
};

type ColumnOrder = {
  column: string;
  direction: "asc" | "desc";
};

export type FilterPreset = {
  id?: number;
  dashboardId: string;
  name: string;
  columns: PersistedColumn[];
  order: ColumnOrder[];
  filters: Filter[];
};

type ApiFilterPreset = Omit<FilterPreset, "filters"> & {
  filters: ApiFilter[];
};

const filterPresetTags = createTagTypes({
  tagPrefix: "FilterPreset",
  tags: ["filterPreset"],
});

function fromApiFilterPreset(filterPreset: ApiFilterPreset): FilterPreset {
  return {
    ...filterPreset,
    filters: filterPreset.filters.map(({ label, value, ...filter }) => ({
      label,
      value,
      labelDimension: convertStringToCubeDimension(filter.labelColumn),
      valueDimension: convertStringToCubeDimension(filter.valueColumn),
    })),
  };
}

function toApiFilterPreset(filterPreset?: Partial<FilterPreset>): Partial<ApiFilterPreset> | undefined {
  return (
    filterPreset && {
      ...filterPreset,
      filters: filterPreset.filters?.map(({ label, value, ...filter }) => ({
        label,
        value,
        labelColumn: cubeDimensionToKey(filter.labelDimension),
        valueColumn: cubeDimensionToKey(filter.valueDimension),
      })),
    }
  );
}

const endpointsV3 = simplicateApiV3.enhanceEndpoints({ addTagTypes: Object.values(filterPresetTags) }).injectEndpoints({
  endpoints: (builder) => ({
    getFilterPresetsList: builder.query<FilterPreset[], string>({
      query: (dashboardId) => ({
        url: `/insight.filterPreset.list`,
        params: {
          dashboardId,
        },
      }),
      transformResponse: (result: ApiFilterPreset[]) => {
        return result.map(fromApiFilterPreset);
      },
      providesTags: [filterPresetTags.filterPreset],
    }),

    getFilterPreset: builder.query<FilterPreset, number>({
      query: (viewId) => ({
        url: `/insight.filterPreset.get/${viewId}`,
      }),
      transformResponse: fromApiFilterPreset,
    }),

    createFilterPreset: builder.mutation<FilterPreset, Partial<FilterPreset>>({
      query: (data) => ({
        url: "/insight.filterPreset.create",
        method: "POST",
        body: toApiFilterPreset(data),
        transformResponse: fromApiFilterPreset,
      }),
      invalidatesTags: [filterPresetTags.filterPreset],
    }),

    updateFilterPreset: builder.mutation<FilterPreset, Partial<FilterPreset>>({
      query: (data) => ({
        url: `/insight.filterPreset.update/${data.id}`,
        method: "PUT",
        body: toApiFilterPreset(data),
        transformResponse: fromApiFilterPreset,
      }),
      invalidatesTags: [filterPresetTags.filterPreset],
    }),

    deleteFilterPreset: builder.mutation<void, number>({
      query: (viewId) => ({
        url: `/insight.filterPreset.delete/${viewId}`,
        method: "DELETE",
      }),
      invalidatesTags: [filterPresetTags.filterPreset],
    }),
  }),
});

export const {
  useCreateFilterPresetMutation,
  useDeleteFilterPresetMutation,
  useGetFilterPresetQuery,
  useGetFilterPresetsListQuery,
  useUpdateFilterPresetMutation,
} = endpointsV3;
