import { useGetEmployeeQuery } from "@simplicate/api-client";
import { Avatar, SkeletonLoader } from "@simplicate/ui";
import { size7 } from "@simplicate-software/design-tokens";
import styles from "./Employee.module.scss";

type EmployeeProps = {
  id: string;
  testId?: string;
};

export const Employee = ({ id, testId }: EmployeeProps) => {
  const { data, isFetching } = useGetEmployeeQuery(id);

  return (
    <div className={styles.employee}>
      {!isFetching && data ? (
        <>
          <Avatar
            size="small"
            altText={data.data.avatar?.initials}
            src={data.data.avatar?.url_small}
            color={data.data.avatar?.color}
          />
          {data.data.name}
        </>
      ) : (
        <SkeletonLoader width={size7} testId={testId && `${testId}-skeleton`} />
      )}
    </div>
  );
};
