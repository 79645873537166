import { format } from "date-fns";
import styles from "./InvoicedUntil.module.scss";

type InvoicedUntilProps = {
  value: Date;
  label: string;
};

const DATE_FORMAT = "dd MMMM yyyy";

export const InvoicedUntil = ({ value, label }: InvoicedUntilProps) => (
  <div className={styles.wrapper}>
    <span className={styles.label}>{label}</span>
    <span className={styles.date}>{format(value, DATE_FORMAT)}</span>
  </div>
);
