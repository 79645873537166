import { FeatureFlagSwitch, useFeatureFlag } from "@simplicate/api-client";
import { HoursReportPage, HoursReportPageDetails, IndexPage, WorkflowsReportPageDetails } from "@simplicate/insights";
import { Navigate, Outlet } from "react-router-dom";
import { useIsAdministrator } from "../../components/MainMenu/Insights/useIsAdministrator";
import { RouteObject } from "../types";

const PermissionGuard = () => {
  const { isAdministrator, isLoading } = useIsAdministrator();
  const { enabled: endUserHasAccess, isFetching } = useFeatureFlag("insights-end-user-access");

  if (isLoading || isFetching) {
    return null;
  }

  if (!isAdministrator && !endUserHasAccess) {
    return <Navigate to="/404" replace />;
  }

  return <Outlet />;
};

export const insightsRoutes: RouteObject[] = [
  {
    path: "insights",
    element: <PermissionGuard />,
    children: [
      {
        index: true,
        element: (
          <FeatureFlagSwitch featureFlag="insights" ifEnabled={<IndexPage />} ifDisabled={<Navigate to="/404" />} />
        ),
      },
      {
        path: "dashboard",
        handle: {
          // TODO: fix the bug causing this necessity. See SIM-37919
          headerTitle: undefined,
          permissions: [{ module_key: "hours", right_key: "hoursrapportage_all" }],
        },
        children: [
          {
            index: true,
            element: <Navigate to="/insights" />,
            handle: {
              backNavigationPath: "/insights",
            },
          },
          {
            path: "hours",
            element: (
              <FeatureFlagSwitch
                featureFlag="insights"
                ifEnabled={<HoursReportPage />}
                ifDisabled={<Navigate to="/404" />}
              />
            ),
            handle: {
              backNavigationPath: "/insights",
            },
          },
          {
            path: "hours-details",
            element: (
              <FeatureFlagSwitch
                featureFlag="insights"
                ifEnabled={<HoursReportPageDetails />}
                ifDisabled={<Navigate to="/404" />}
              />
            ),
            handle: {
              backNavigationPath: "/insights",
            },
          },
          {
            path: "workflow-details",
            element: (
              <FeatureFlagSwitch
                featureFlag="insights"
                ifEnabled={<WorkflowsReportPageDetails />}
                ifDisabled={<Navigate to="/404" />}
              />
            ),
            handle: {
              backNavigationPath: "/insights",
            },
          },
        ],
      },
    ],
    handle: {
      theme: "insights",
      headerTitle: "module_insights",
      permissions: [
        { module_key: "hrm", right_key: "rapportage" },
        { module_key: "acquisition", right_key: "rapportage" },
        { module_key: "hours", right_key: "hoursrapportage_all" },
      ],
    },
  },
];
