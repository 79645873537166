import { simplicateApiV3, type SubscriptionCycle } from "@simplicate/api-client";

export type SubscriptionCycles = { cycleOptions: SubscriptionCycle[] };

const endpoints = simplicateApiV3.injectEndpoints({
  endpoints: (builder) => ({
    getSubscriptionCycles: builder.query<SubscriptionCycles, void>({
      query: () => ({
        url: `/subscriptionCycle.list`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetSubscriptionCyclesQuery } = endpoints;
