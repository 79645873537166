import { useNumericFormatter } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { Alert, Link } from "@simplicate/ui";
import { Big } from "../../../../../data";
import styles from "./HourTypeTotalsInfo.module.scss";
import type { HourTypeInForm, MoneyInForm } from "../../../types";

type HourTypesTotalsInfo = {
  hourTypes?: HourTypeInForm[];
  specifiedTotal?: MoneyInForm;
  setSpecifiedTotal: (newTotal: Big) => void;
};

export const HourTypesTotalsInfo = ({ hourTypes = [], specifiedTotal, setSpecifiedTotal }: HourTypesTotalsInfo) => {
  const { t } = useTranslation("project_services");
  const calculatedTotal = hourTypes.reduce(
    (calculatedTotal, current) => calculatedTotal.add(current.total.amount ?? 0),
    Big(0),
  );

  const formattedCalculatedTotal = useNumericFormatter(calculatedTotal?.toString());

  if (!specifiedTotal?.amount) {
    return;
  }

  if (calculatedTotal.cmp(specifiedTotal.amount) === 0) {
    return;
  }

  return (
    <div className={styles.alertContainer} data-testid="hour-types-totals-info">
      <Alert type="info">
        <div className={styles.totalInfo}>
          <span>
            {t("specified_total_differs_from_calculated_{{value}}", {
              value: formattedCalculatedTotal,
            })}
          </span>

          <Link data-testid="reset-specified-total-button" onClick={() => setSpecifiedTotal(calculatedTotal)}>
            {t("reset_specified_total")}
          </Link>
        </div>
      </Alert>
    </div>
  );
};
