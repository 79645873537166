import { useTranslation } from "@simplicate/translations";
import { Select, Tooltip } from "@simplicate/ui";
import { memo } from "react";
import { type SubscriptionThatCanBeInvoicedTogether } from "../../../data";
import styles from "./InvoiceTogetherWith.module.scss";

type InvoiceTogetherWithProps = {
  value?: string;
  onChange: (value: string | undefined) => void;
  services: SubscriptionThatCanBeInvoicedTogether[];
  missingFields: string[];
  disabled: boolean;
};

export const InvoiceTogetherWith = memo(function InvoiceTogetherWith({
  value,
  onChange,
  services,
  missingFields,
  disabled,
}: InvoiceTogetherWithProps) {
  const { t } = useTranslation("project_services");

  const options = services?.map(({ id, description }) => ({
    label: description,
    value: id,
  }));

  let tooltip = "";
  if (services.length === 0) tooltip = t("invoice_together_with_empty");
  if (missingFields.length > 0) tooltip = t("invoice_together_with_disabled", { fields: missingFields.join(", ") });

  const select = (
    <div className={styles.select}>
      <Select
        testId="invoice_together_with_select"
        label={t("invoice_together_with")}
        options={options}
        onSelect={onChange}
        value={value}
        disabled={disabled || services.length === 0}
        placeholder={t("invoice_together_with_placeholder")}
        showClear
      />
    </div>
  );

  return tooltip ? (
    <Tooltip message={tooltip} position="right">
      {select}
    </Tooltip>
  ) : (
    select
  );
});
