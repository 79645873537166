// istanbul ignore file -- This is lazy loading logic
import { Spinner } from "@simplicate/ui";
import { Suspense, lazy } from "react";

const Component = lazy(() => import("./DashboardPage"));

export const DashboardPage = () => {
  return (
    <Suspense fallback={<Spinner size="large" />}>
      <Component />
    </Suspense>
  );
};
