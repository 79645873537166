import { ConversionRecord } from "../types";
import { extractFragment } from "./utils";

const INTEGRATIONS = [
  "twinfieldpurchase",
  "exactpurchase",
  "jiraChromeExtension",
  "slack",
  "peppol",
  "hubspot",
  "mollie",
  "clockAssist",
  "dummy",
  "mailchimp",
  "jiraIntegration",
] as const;

const INTEGRATIONS_WITH_MAPPING_PAGE = ["fiscaalgemak", "nextens", "hyarchis", "pinkweb"] as const;

const ACCOUNTANCY_INTEGRATIONS = ["fiscaalgemak", "nextens", "hyarchis", "pinkweb"] as const;

export const EnvironmentManagementRouteConversions: ConversionRecord = {
  environmentmanagement: () => ({ status: "complete", location: "/settings" }),
  // Account
  "/environmentmanagement/subscription": (location) => {
    const fragment = extractFragment(location);

    switch (fragment) {
      case "#company_data":
        return {
          status: "complete",
          location: "/settings/subscription/company_data",
        };
      case "#time_line":
        return {
          status: "complete",
          location: "/settings/subscription/time_line",
        };
      default:
        return {
          status: "complete",
          location: "/settings/subscription",
        };
    }
  },
  "/environmentmanagement/accountsettings": (location) => {
    const suffix = extractFragment(location).replace("#", "");

    return {
      status: "complete",
      location: suffix ? `/settings/accountsettings/${suffix}` : "/settings/accountsettings",
    };
  },
  "/environmentmanagement/administrationsettings": () => ({
    status: "complete",
    location: "/settings/administrationsettings",
  }),
  "/environmentmanagement/administrationsettings/view": (location) => ({
    status: "complete",
    location: `/settings/administrationsettings/view?${location.searchParams.toString()}${extractFragment(location)}`,
  }),
  "/environmentmanagement/usersandgroups": (location) => ({
    status: "complete",
    location: `/settings/usersandgroups${extractFragment(location) || "#user"}`,
  }),
  "/environmentmanagement/usersandgroups/view": (location) => ({
    status: "complete",
    location: `/settings/usersandgroups/view?${location.searchParams.toString()}`,
  }),
  "/environmentmanagement/smartbcc": () => ({ status: "complete", location: "/settings/smartbcc" }),
  "/environmentmanagement/mailmanager": (location) => {
    const suffix = extractFragment(location).replace("#", "");

    return {
      status: "complete",
      location: suffix === "mailmanager" || !suffix ? `/settings/mailmanager` : `/settings/mailmanager/${suffix}`,
    };
  },
  "/environmentmanagement/notifications": () => ({
    status: "complete",
    location: "/settings/notifications",
  }),
  "/environmentmanagement/integrations": () => ({ status: "complete", location: "/settings/integrations" }),
  "/environmentmanagement/api": () => ({ status: "complete", location: "/settings/api" }),

  // General -- admin users
  "/environmentmanagement/import": () => ({ status: "complete", location: "/settings/import" }),
  "/environmentmanagement/import/projects": (location) => {
    const suffix = extractFragment(location);

    return {
      status: "complete",
      location: `/settings/import/projects?${location.searchParams.toString()}${suffix}`,
    };
  },
  "/environmentmanagement/general/restoreHours": () => ({
    status: "complete",
    location: "/settings/general/restoreHours",
  }),
  "/environmentmanagement/general/importOverview": () => ({
    status: "complete",
    location: "/settings/general/importOverview",
  }),
  "/environmentmanagement/default/settingsLogging": () => ({
    status: "complete",
    location: "/settings/default/settingsLogging",
  }),
  "/environmentmanagement/default/relationActionGrid": () => ({
    status: "complete",
    location: "/settings/default/relationActionGrid",
  }),
  "/environmentmanagement/general/skippedInvoiceNumbersGrid": () => ({
    status: "complete",
    location: "/settings/general/skippedInvoiceNumbersGrid",
  }),

  // General
  "/environmentmanagement/customfields": (location) => ({
    status: "complete",
    location: `/settings/customfields${extractFragment(location)}`,
  }),
  "/environmentmanagement/dossier": (location) => ({
    status: "complete",
    location: `/settings/dossier${extractFragment(location)}`,
  }),
  "/environmentmanagement/dossier/view": (location) => ({
    status: "complete",
    location: `/settings/dossier/view?${location.searchParams.toString()}${extractFragment(location)}`,
  }),
  "/environmentmanagement/dossier/workflowgroup": (location) => ({
    status: "complete",
    location: `/settings/dossier/workflowgroup?${location.searchParams.toString()}`,
  }),
  "/environmentmanagement/templates/view": (location) => ({
    status: "complete",
    location: `/settings/templates/view?${location.searchParams.toString()}`,
  }),
  "/environmentmanagement/document": () => ({ status: "complete", location: "/settings/document" }),
  "/environmentmanagement/document/view": (location) => ({
    status: "complete",
    location: `/settings/document/view?${location.searchParams.toString()}`,
  }),
  "/environmentmanagement/imports": () => ({ status: "complete", location: "/settings/imports" }),
  "/environmentmanagement/paperlayout": () => ({ status: "complete", location: "/settings/paperlayout" }),
  "/environmentmanagement/paperlayout/paperlayout": (location) => ({
    status: "complete",
    location: `/settings/paperlayout/paperlayout?${location.searchParams.toString()}`,
  }),

  // CRM
  "/environmentmanagement/crm": (location) => ({
    status: "complete",
    location: `/settings/crm${extractFragment(location)}`,
  }),
  "/environmentmanagement/crm/view": (location) => ({
    status: "complete",
    location: `/settings/crm/view?${location.searchParams.toString()}${extractFragment(location)}`,
  }),
  "environmentmanagement/crm/accountancycustomfields": (location) => ({
    status: "complete",
    location: `/settings/crm/accountancycustomfields?${location.searchParams.toString()}`,
  }),

  // HRM
  "/environmentmanagement/hrm": (location) => ({
    status: "complete",
    location: `/settings/hrm${extractFragment(location)}`,
  }),
  "/environmentmanagement/hrm/view": (location) => ({
    status: "complete",
    location: `/settings/hrm/view?${location.searchParams.toString()}${extractFragment(location)}`,
  }),

  // Sales
  "/environmentmanagement/acquisition": (location) => {
    const fragment = extractFragment(location);

    switch (fragment) {
      case "#templates_new":
        return {
          status: "complete",
          location: "/settings/sales/templates",
        };

      case "#quotecontentblocks":
        return {
          status: "complete",
          location: "/settings/sales/quotecontentblocks",
        };

      default:
        return {
          status: "complete",
          location: `/settings/sales${extractFragment(location)}`,
        };
    }
  },
  "environmentmanagement/acquisition/view": (location) => ({
    status: "complete",
    location: `/settings/sales/view?${location.searchParams.toString()}${extractFragment(location)}`,
  }),

  // Projects
  "/environmentmanagement/projects": (location) => ({
    status: "complete",
    location: `/settings/projects${extractFragment(location) || "#projectssettings"}`,
  }),
  "/environmentmanagement/projects/view": (location) => ({
    status: "complete",
    location: `/settings/projects/view?${location.searchParams.toString()}${extractFragment(location)}`,
  }),
  "/environmentmanagement/projects/priceIndexing": () => ({
    status: "complete",
    location: "/settings/projects/priceIndexing",
  }),

  // Resource Planner
  "/environmentmanagement/resourceplanner": () => ({
    status: "complete",
    location: "/settings/resourceplanner/general",
  }),
  "/environmentmanagement/resourceplanner/general": () => ({
    status: "complete",
    location: "/settings/resourceplanner/general",
  }),
  "/environmentmanagement/resourceplanner/assignmentstatuses": () => ({
    status: "complete",
    location: "/settings/resourceplanner/assignmentstatuses",
  }),

  // Hours
  "/environmentmanagement/hours": () => ({
    status: "complete",
    location: "/settings/hours/register",
  }),
  "/environmentmanagement/hours/register": () => ({
    status: "complete",
    location: "/settings/hours/register",
  }),
  "/environmentmanagement/hours/review": () => ({
    status: "complete",
    location: "/settings/hours/review",
  }),

  // Invoices
  "/environmentmanagement/invoices": (location) => ({
    status: "complete",
    location: `/settings/invoices${extractFragment(location)}`,
  }),
  "/environmentmanagement/invoices/view": (location) => ({
    status: "complete",
    location: `/settings/invoices/view?${location.searchParams.toString()}${extractFragment(location)}`,
  }),
  "/environmentmanagement/invoices/invoicetemplate": (location) => ({
    status: "complete",
    location: `/settings/invoices/invoicetemplate?${location.searchParams.toString()}${extractFragment(location)}`,
  }),
  "/environmentmanagement/invoices/remindertemplate": (location) => ({
    status: "complete",
    location: `/settings/invoices/remindertemplate?${location.searchParams.toString()}${extractFragment(location)}`,
  }),
  "/environmentmanagement/invoices/reminderset": (location) => ({
    status: "complete",
    location: `/settings/invoices/reminderset?${location.searchParams.toString()}`,
  }),
  "/environmentmanagement/invoices/remindersettemplate": (location) => ({
    status: "complete",
    location: `/settings/invoices/remindersettemplate?${location.searchParams.toString()}`,
  }),
  "/environmentmanagement/apps/accountancyApps": () => ({
    status: "complete",
    location: "/settings/apps/accountancyApps",
  }),
  "/environmentmanagement/apps/installExternalAccounting": (location) => ({
    status: "complete",
    location: `/settings/apps/accountancyApps/install?${location.searchParams.toString()}`,
  }),
  "/environmentmanagement/apps/install": (location) => ({
    status: "complete",
    location: `/settings/apps/install?${location.searchParams.toString()}`,
  }),
  "/apps/twinfield/install": (location) => ({
    status: "navigate-top-level",
    location: `/apps/twinfield/install?${location.searchParams.toString()}`,
  }),
  "/environmentmanagement/apps/configure": (location) => ({
    status: "complete",
    location: `/settings/apps/configure?${location.searchParams.toString()}`,
  }),

  //integrations

  "/v1/integrations": () => ({ status: "complete", location: "/settings/integrations" }),
  "/v1/integrations/mollie/verifyapikey": (location) => ({
    status: "complete",
    location: `/settings/integrations/mollie/verifyapikey?${location.searchParams.toString()}`,
  }),
  "/v1/integrations/mollie/revoke": () => ({
    status: "complete",
    location: `/settings/integrations/mollie/revoke`,
  }),

  "/v1/integrations/exactpurchase/auth": (location) => ({
    status: "navigate-top-level",
    location: `/v1/integrations/exactpurchase/auth?${location.searchParams.toString()}`,
  }),

  "/v1/integrations/slack/refreshtoken": (location) => ({
    status: "navigate-top-level",
    location: `/v1/integrations/slack/refreshtoken?${location.searchParams.toString()}`,
  }),
  "/v1/integrations/slack/refreshreceivers": (location) => ({
    status: "complete",
    location: `/settings/integrations/slack/refreshreceivers?${location.searchParams.toString()}`,
  }),
  "/v1/integrations/slack/auth": (location) => ({
    status: "navigate-top-level",
    location: `/v1/integrations/slack/auth?${location.searchParams.toString()}`,
  }),
  "/v1/integrations/slack/callback": (location) => ({
    status: "complete",
    location: `/settings/integrations/slack/callback?${location.searchParams.toString()}`,
  }),
  "/v1/integrations/slack/revoke": (location) => ({
    status: "complete",
    location: `/settings/integrations/slack/revoke?${location.searchParams.toString()}`,
  }),

  "/v1/integrations/clockAssist/redirectToClockAssist": (location) => ({
    status: "complete",
    location: `/settings/integrations/clockAssist/redirectToClockAssist?${location.searchParams.toString()}`,
  }),

  "/v1/integrations/peppol/CompanyProfileVerification": (location) => ({
    status: "navigate-top-level",
    location: `/v1/integrations/peppol/CompanyProfileVerification?${location.searchParams.toString()}`,
  }),

  "/v1/integrations/externallistwebhook/reinstallWebhooks": (location) => ({
    status: "complete",
    location: `/settings/integrations/mailchimp/reinstallWebhooks?${location.searchParams.toString()}`,
  }),

  "/v1/integrations/api/webhooks/mailchimp": (location) => ({
    status: "complete",
    location: `/settings/integrations/mailchimp/webhooks?${location.searchParams.toString()}`,
  }),

  "/v1/integrations/twinfieldpurchase/auth": (location) => ({
    status: "navigate-top-level",
    location: `/v1/integrations/twinfieldpurchase/auth?${location.searchParams.toString()}`,
  }),
  ...[...INTEGRATIONS, ...INTEGRATIONS_WITH_MAPPING_PAGE, ...ACCOUNTANCY_INTEGRATIONS].reduce<ConversionRecord>(
    (acc, integration) => {
      acc[`v1/integrations/${integration}`] = () => ({
        status: "complete",
        location: `/settings/integrations/${integration}`,
      });

      return acc;
    },
    {},
  ),

  ...ACCOUNTANCY_INTEGRATIONS.reduce<ConversionRecord>((acc, integration) => {
    acc[`v1/integrations/${integration}/${integration}`] = () => ({
      status: "complete",
      location: `/settings/integrations/${integration}/${integration}`,
    });

    return acc;
  }, {}),

  ...ACCOUNTANCY_INTEGRATIONS.reduce<ConversionRecord>((acc, integration) => {
    acc[`v1/integrations/${integration}/revoke`] = () => ({
      status: "complete",
      location: `/settings/integrations/${integration}/revoke`,
    });

    return acc;
  }, {}),

  ...INTEGRATIONS_WITH_MAPPING_PAGE.reduce<ConversionRecord>((acc, integration) => {
    acc[`v1/integrations/${integration}/mapping`] = (location) => ({
      status: "complete",
      location: `/settings/integrations/${integration}/mapping?${location.searchParams.toString()}${extractFragment(location)}`,
    });

    return acc;
  }, {}),

  "/imports": () => ({ status: "complete", location: "/settings/imports" }),

  "/integrations/exactpurchase/callback": (location) => ({
    status: "complete",
    location: `/settings/integrations/exactpurchase/callback?${location.searchParams.toString()}`,
  }),
};
