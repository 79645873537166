import { DeeplyReadonly, Query, TimeDimension } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import { format } from "date-fns";
import { useMemo } from "react";
import { type DashboardState } from "../../../components/Dashboard/Dashboard.reducer";
import { cubeDimensionToKey } from "../../../types";

function formatDate(date: Date) {
  return format(date, "yyyy-MM-dd");
}

type CubeQuery = DeeplyReadonly<Query> & {
  state?: DashboardState;
};

export const useDataset = ({ state, ...query }: CubeQuery) => {
  const { filters, order, timeDimensions } = useMemo(() => {
    const userAppliedFilters = state?.filters
      ? [
          {
            or: state?.filters.map(({ valueDimension, value }) => ({
              member: cubeDimensionToKey(valueDimension),
              operator: "equals" as const,
              values: Array.isArray(value) ? value : [value],
            })),
          },
        ]
      : [];

    const filters = query.filters
      ? [
          {
            and: [...query.filters, ...userAppliedFilters],
          },
        ]
      : userAppliedFilters;

    const order = state?.order?.map(({ column, direction }) => [column, direction] as const);

    const dateRange = state?.dateRange
      ? ([formatDate(state.dateRange.start), formatDate(state.dateRange.end)] as const)
      : "this week";
    const timeDimensions: DeeplyReadonly<TimeDimension[] | undefined> = query.timeDimensions
      ? query.timeDimensions.map((timeDimension) => ({ ...timeDimension, dateRange }))
      : undefined;

    return { filters, order, timeDimensions };
  }, [query.filters, query.timeDimensions, state?.dateRange, state?.filters, state?.order]);

  return useCubeQuery({
    ...query,
    filters: filters.length > 0 ? filters : undefined,
    order,
    timeDimensions,
  });
};
