import { skipToken, useGetOrganizationQuery } from "@simplicate/api-client";
import { PageHeader } from "@simplicate/top-bar";
import { useTranslation } from "@simplicate/translations";
import { Tag } from "@simplicate/ui";
import { useParams } from "react-router-dom";

export const OrganizationSubHeader = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation("top_bar");

  const { data: organization } = useGetOrganizationQuery(id ?? skipToken, { refetchOnMountOrArgChange: true });

  if (!organization) {
    return null;
  }

  const organizationName = organization.data.name;
  const relationTypeLabel = organization.data.relation_type?.label;
  const relationTypeColor = organization.data.relation_type?.color;
  const organizationIsActive = organization.data.is_active;
  const relationNumber = organization.data.relation_number;

  const nameOrFallbackName = organizationName ? organizationName : t("default_new_crm_title");

  const title = relationNumber ? `${relationNumber}. ${nameOrFallbackName}` : nameOrFallbackName;

  return (
    <PageHeader title={title}>
      {relationTypeLabel && (
        <Tag text={relationTypeLabel} color={relationTypeColor} testId="relation-type" size="small" variant="color" />
      )}
      {!organizationIsActive && <Tag text={t("inactive")} testId="organization-type" size="small" />}
      <Tag text={t("organization")} testId="organization-type" size="small" />
    </PageHeader>
  );
};
