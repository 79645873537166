import { skipToken } from "@simplicate/api-client";
import { useEffect } from "react";
import { useBuildProjectServiceQuery, BuiltProjectService } from "../../../data";

type useBuildProjectServiceProps = {
  projectId: string | undefined;
  isEditingService: boolean;
  defaultServiceValue: string | undefined;
  defaultServiceTouched: boolean;
  setValuesFromProjectService: (projectService: BuiltProjectService) => void;
};

export const useBuildProjectService = ({
  defaultServiceValue,
  defaultServiceTouched,
  projectId,
  isEditingService,
  setValuesFromProjectService,
}: useBuildProjectServiceProps) => {
  const shouldFetchWhenCreating = !isEditingService;
  const shouldFetchWhenEditing = isEditingService && defaultServiceTouched;

  const shouldFetch =
    projectId !== undefined && defaultServiceValue !== undefined && (shouldFetchWhenCreating || shouldFetchWhenEditing);

  const {
    data: projectService,
    isSuccess,
    isFetching,
  } = useBuildProjectServiceQuery(
    shouldFetch ? { defaultServiceId: defaultServiceValue, projectId: projectId } : skipToken,
  );

  useEffect(() => {
    if (isFetching || !isSuccess || projectService == undefined) {
      return;
    }

    setValuesFromProjectService(projectService);
  }, [isSuccess, projectService, setValuesFromProjectService, isFetching]);
};
